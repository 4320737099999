import { IValidationError, ValidatorTypesEnum } from './validators';

// UI
export enum ButtonEnum {
    Primary = 'btn btn--primary',
    Secondary = 'btn btn--secondary',
    Help = 'btn btn--help',
}

// User
export interface IUser {
    name: string;
    company?: string;
}

// Input
export enum InputElementEnum {
    text = 'text',
    email = 'email',
    phone = 'phone',
    search = 'search',
    number = 'number',
    date = 'date',
    textarea = 'textarea',
    select = 'select',
    checkbox = 'checkbox',
    checkboxGroup = 'checkbox-group',
    radio = 'radio',
    radioGroup = 'radio-group',
}

export interface IOnInputChange {
    onInputChange?: (name: string, value: any) => void;
}

export interface IOnInputBlur {
    onInputBlur?: (name: string) => void;
}

export interface IInputValidation {
    validateOnChange?: boolean;
    validators?: Array<ValidatorTypesEnum>;
    validationError?: IValidationError;
}

export interface IInputElement extends IOnInputChange, IOnInputBlur, IInputValidation {
    type: InputElementEnum;
    label: string;
    helpText?: string;
    placeholder?: string;
    name: string;
    value: any;
    focus: string;
    disabled?: boolean;
}
