import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import api from '../../shared/lib/api';
import TextInput from '../../../form/TextInput';
import {
    IKundeForm,
    mapKundeToKundeForm,
    mapKundeFormToKunde,
    createKunde,
    createKontaktperson,
    mapKontaktpersonToKontaktpersonForm,
    updateInput,
} from './kundeFormHelper';
import Kontaktperson from '../../bedrift/Kontaktperson';
import BedriftAdresse from '../../bedrift/BedriftAdresse';
import Rettigheter from './Rettigheter';
import { getQueryParam } from '../../../../shared/lib/utils';
import { Link } from 'react-router-dom';
import { getValidationResult, validateInput } from '../../../../shared/lib/validators';
import { IRadgiver } from '../../shared/lib/types';
import SelectInput, { ISelectInputOption } from '../../../form/SelectInput';
import CheckboxInput from '../../../form/CheckboxInput';
import { createServerErrorMessage, getInnloggetRadgiver, getRadgiverFullName } from '../kundeHelper';
import FeilmeldingSide, { getMessageFromErrorCode } from '../../feilside/FeilmeldingSide';
import Spinner from '../../spinner/Spinner';

type TParams = {
    kundeId: string;
};

const RedigerKundedetaljer = () => {
    let navigate = useNavigate();
    const { kundeId } = useParams<TParams>();
    const [loading, setLoading] = useState<boolean>(true);
    const [orgnrReadonly, setOrgnrReadonly] = useState<boolean>(false);
    const [kundeForm, setKundeForm] = useState<IKundeForm>(mapKundeToKundeForm(createKunde()));
    const [radgivere, setRadgivere] = useState<Array<IRadgiver>>([]);
    const [serverErrorMessage, setServerErrorMessage] = useState<Array<string>>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);

    useEffect(() => {
        (function getKunde() {
            api.getRadgivere()
                .then((radgivere) => {
                    setRadgivere(radgivere);
                    // If we have a kunde-context, get them from api
                    if (kundeId) {
                        api.getKunde(kundeId).then((kunde) => {
                            setKundeNavn(kunde.navn);
                            setKundeForm(mapKundeToKundeForm(kunde));
                            setOrgnrReadonly(true);
                        });

                        return;
                    }
                    // If we dont have a kunde-context
                    const innloggetRadgiver = getInnloggetRadgiver(radgivere);
                    const lookup = getQueryParam('lookup');
                    // We are in "lookup"-mode
                    if (lookup) {
                        api.getKundeByOrgnr(lookup)
                            .then((kunde) => {
                                kunde.kundeansvarlig = innloggetRadgiver;
                                setKundeForm(mapKundeToKundeForm(kunde));
                                setOrgnrReadonly(true);
                            })
                            .catch(() => {
                                setKundeForm((kundeForm) => {
                                    return {
                                        ...kundeForm,
                                        organisasjonsnummer: {
                                            ...kundeForm.organisasjonsnummer,
                                            validationError: {
                                                message: 'Oppslag på dette organisasjonsnummeret ga ingen resultater.',
                                                name: kundeForm.organisasjonsnummer.name,
                                            },
                                        },
                                    };
                                });
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                        return;
                    }

                    const orgnr = getQueryParam('orgnr');

                    // We are in "manual"-mode
                    setKundeForm((kundeForm) => {
                        return {
                            ...kundeForm,
                            organisasjonsnummer: {
                                ...kundeForm.organisasjonsnummer,
                                value: orgnr ?? null,
                            },
                            kundeansvarlig: {
                                brukerId: updateInput(kundeForm.kundeansvarlig.brukerId, innloggetRadgiver.brukerId),
                            },
                        };
                    });
                })
                .catch((res) => setServerErrorCode(res.status))
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Rediger kunde';
        })();
    }, [kundeId]);

    function onDeleteKontaktpersonClick(index: number) {
        let kontaktpersoner = [...(kundeForm.kontaktpersoner ?? [])];
        kontaktpersoner.splice(index, 1);
        setKundeForm({ ...kundeForm, kontaktpersoner });
    }

    function onAddKontaktperson() {
        let kontaktpersoner = [...(kundeForm.kontaktpersoner ?? [])];
        kontaktpersoner.push(mapKontaktpersonToKontaktpersonForm(createKontaktperson(), `kontaktpersoner[${kundeForm.kontaktpersoner.length}]`));
        setKundeForm({ ...kundeForm, kontaktpersoner });
    }

    function onSaveClick() {
        const result = getValidationResult(kundeForm);
        setKundeForm(result.form as IKundeForm);

        if (result.errors.length > 0) {
            return;
        }

        const kunde = mapKundeFormToKunde(kundeForm);
        const innloggetRadgiver = getInnloggetRadgiver(radgivere);

        if (
            kunde.harBegrensetKundesynlighet &&
            kunde.kundeansvarlig.brukerId !== innloggetRadgiver.brukerId &&
            !kunde.rettigheter.some((x) => x.brukerId === innloggetRadgiver.brukerId)
        ) {
            const confirm = window.confirm(
                'Din bruker er ikke blandt rådgivere med rettigheter. \nDenne handlingen vil gjøre at du ikke lenger kan se denne kunden. \nEr du sikker på at du vil gjøre dette?'
            );
            if (!confirm) {
                return;
            }
        }

        setServerErrorMessage(null);

        api.postKunde(kunde)
            .then((kundeId) => {
                navigate(`/kunder/${kundeId}`);
            })
            .catch((res) => {
                if (res.data && res.data.errors) {
                    const errorMessage = createServerErrorMessage(res.data.errors);
                    if (errorMessage && !!errorMessage.length) {
                        setServerErrorMessage(errorMessage);
                        return;
                    }
                }
                setServerErrorMessage([getMessageFromErrorCode(res.status as number)]);
            });
    }

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <>
            <div className="page-header">
                <div className="container">
                    <h1>{kundeId ? `Rediger kunde ` : 'Opprett kunde'}</h1>
                    {kundeNavn && <h2>{kundeNavn}</h2>}
                </div>
            </div>
            <div className="page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-8">
                            <div className="section section--white">
                                {!loading && kundeForm && (
                                    <div>
                                        <TextInput
                                            {...kundeForm.organisasjonsnummer}
                                            readonly={orgnrReadonly}
                                            onInputChange={(name, value) => {
                                                setKundeForm({
                                                    ...kundeForm,
                                                    organisasjonsnummer: updateInput(kundeForm.organisasjonsnummer, value),
                                                });
                                            }}
                                            onInputBlur={(name) => {
                                                setKundeForm({
                                                    ...kundeForm,
                                                    organisasjonsnummer: validateInput(kundeForm.organisasjonsnummer),
                                                });
                                            }}
                                        />
                                        <TextInput
                                            {...kundeForm.navn}
                                            onInputChange={(name, value) => {
                                                setKundeForm({
                                                    ...kundeForm,
                                                    navn: updateInput(kundeForm.navn, value),
                                                });
                                            }}
                                            onInputBlur={(name) => {
                                                setKundeForm({
                                                    ...kundeForm,
                                                    navn: validateInput(kundeForm.navn),
                                                });
                                            }}
                                        />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextInput
                                                    {...kundeForm.epost}
                                                    onInputChange={(name, value) => {
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            epost: updateInput(kundeForm.epost, value),
                                                        });
                                                    }}
                                                    onInputBlur={(name) => {
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            epost: validateInput(kundeForm.epost),
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextInput
                                                    {...kundeForm.telefonnummer}
                                                    onInputChange={(name, value) => {
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            telefonnummer: updateInput(kundeForm.telefonnummer, value),
                                                        });
                                                    }}
                                                    onInputBlur={(name) => {
                                                        let input = { ...kundeForm.telefonnummer };
                                                        input.validateOnChange = true;
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            telefonnummer: validateInput(input),
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextInput
                                                    {...kundeForm.mobilnummer}
                                                    onInputChange={(name, value) => {
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            mobilnummer: updateInput(kundeForm.mobilnummer, value),
                                                        });
                                                    }}
                                                    onInputBlur={(name) => {
                                                        let input = { ...kundeForm.mobilnummer };
                                                        input.validateOnChange = true;
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            mobilnummer: validateInput(input),
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {radgivere && (
                                            <SelectInput
                                                {...kundeForm.kundeansvarlig.brukerId}
                                                options={radgivere.map((radgiver) => {
                                                    return {
                                                        text: getRadgiverFullName(radgiver),
                                                        value: radgiver.brukerId,
                                                    } as ISelectInputOption;
                                                })}
                                                onInputChange={(name, value) => {
                                                    setKundeForm({
                                                        ...kundeForm,
                                                        kundeansvarlig: {
                                                            brukerId: updateInput(kundeForm.kundeansvarlig.brukerId, value),
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                        {radgivere && !!radgivere.length && kundeForm.kundeansvarlig.brukerId.value && (
                                            <>
                                                <h3>RETTIGHETER</h3>
                                                <CheckboxInput
                                                    {...kundeForm.harBegrensetKundesynlighet}
                                                    checked={kundeForm.harBegrensetKundesynlighet.value}
                                                    index={0}
                                                    onInputChange={(name, value) => {
                                                        setKundeForm({
                                                            ...kundeForm,
                                                            harBegrensetKundesynlighet: {
                                                                ...kundeForm.harBegrensetKundesynlighet,
                                                                value: value,
                                                            },
                                                        });
                                                    }}
                                                />
                                                {!kundeForm.harBegrensetKundesynlighet.value ? (
                                                    <p>Alle rådgivere kan se denne kunden</p>
                                                ) : (
                                                    <Rettigheter
                                                        radgivere={radgivere}
                                                        kundeansvarlig={
                                                            radgivere.filter((x) => x.brukerId === kundeForm.kundeansvarlig.brukerId.value)[0]
                                                        }
                                                        rettigheter={kundeForm.rettigheter.filter(
                                                            (x) => x !== kundeForm.kundeansvarlig.brukerId.value
                                                        )}
                                                        onInputChange={(rettigheter) => {
                                                            setKundeForm({
                                                                ...kundeForm,
                                                                rettigheter: rettigheter,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}

                                        <hr />
                                        <h3>FORRETNINGSADRESSE</h3>
                                        {kundeForm.gateadresse && (
                                            <BedriftAdresse
                                                adresse={kundeForm.gateadresse}
                                                onValueChange={(adresse) => {
                                                    setKundeForm({
                                                        ...kundeForm,
                                                        gateadresse: adresse,
                                                    });
                                                }}
                                            />
                                        )}
                                        <hr />
                                        <h3>POSTADRESSE</h3>
                                        <BedriftAdresse
                                            adresse={kundeForm.postadresse}
                                            onValueChange={(adresse) => {
                                                setKundeForm({
                                                    ...kundeForm,
                                                    postadresse: adresse,
                                                });
                                            }}
                                        />
                                        <hr />
                                        <h3>DAGLIG LEDER</h3>
                                        <Kontaktperson
                                            kontaktperson={kundeForm.dagligLeder}
                                            onValueChange={(kontaktperson) => {
                                                setKundeForm({
                                                    ...kundeForm,
                                                    dagligLeder: kontaktperson,
                                                });
                                            }}
                                        />

                                        <hr />
                                        <h3>ANDRE KONTAKTPERSONER</h3>
                                        {kundeForm.kontaktpersoner &&
                                            kundeForm.kontaktpersoner.map((kontaktperson, i) => {
                                                return (
                                                    <div key={`kontaktperson_${i}`} className="kontaktperson">
                                                        <div className="kontaktperson__details">
                                                            <Kontaktperson
                                                                kontaktperson={kontaktperson}
                                                                showRolleDropdown={true}
                                                                onValueChange={(kontaktperson) => {
                                                                    const kontaktpersoner = [...kundeForm.kontaktpersoner];
                                                                    kontaktpersoner[i] = kontaktperson;
                                                                    setKundeForm({
                                                                        ...kundeForm,
                                                                        kontaktpersoner: kontaktpersoner,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="kontaktperson__actions">
                                                            <button
                                                                onClick={() => {
                                                                    onDeleteKontaktpersonClick(i);
                                                                }}
                                                                className="btn btn--secondary btn--sm"
                                                            >
                                                                Slett
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <button onClick={() => onAddKontaktperson()} className="btn btn--secondary btn--sm">
                                            + Legg til kontaktperson
                                        </button>

                                        {serverErrorMessage && !!serverErrorMessage.length && (
                                            <div className="form__errors">
                                                <h4>Kunne ikke lagre</h4>
                                                <ul>
                                                    {serverErrorMessage.map((error, i) => {
                                                        return <li key={error + i}>{error}</li>;
                                                    })}
                                                </ul>
                                            </div>
                                        )}

                                        <div className="form__actions">
                                            <Link to={kundeId ? `/kunder/${kundeId}/` : '/kunder/'} className="btn btn--secondary">
                                                Avbryt
                                            </Link>
                                            <button
                                                className="btn btn--primary"
                                                onClick={() => {
                                                    onSaveClick();
                                                }}
                                            >
                                                Lagre
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RedigerKundedetaljer;
