import React, { useRef, useState, FormEvent, useEffect } from 'react';
import { IInputElement } from '../../shared/lib/types';
import { inputId } from '../../shared/lib/utils';
import FormError from './FormError';
import FormGroupLabelText from './FormGroupLabelText';

export interface ISelectInput extends IInputElement {
    options: Array<ISelectInputOption>;
    firstOptionText?: string;
    createFirstOption?: boolean;
    answerHidden?: boolean;
    required?: boolean;
    showErrorMessage?: boolean;
    error?: string;
}

export interface ISelectInputOption {
    text: string;
    value: string | any;
}

const SelectInput = (props: ISelectInput) => {
    const [id] = useState(inputId());
    const input = useRef<HTMLSelectElement>(null);
    const firstOptionText = props.firstOptionText ?? '<Velg en>';

    const handleChange = (e: FormEvent<HTMLSelectElement>) => {
        props.onInputChange(props.name, e.currentTarget.value);
    };
    function getSelectInputClass() {
        let className = 'select form-group__input';

        if (props.disabled) {
            className += ' disabled';
        }

        if (props.error) {
            className += ' error-input';
        }

        return className;
    }
    useEffect(() => {
        if (props.name === props.focus) {
            input?.current?.focus();
        }
    }, [props.focus, props.name]);

    return (
        <div className="form-group form-group--select">
            <label htmlFor={id}>
                <FormGroupLabelText label={`${props.label}${props.required ? '*' : ''}`} helpText={props.helpText} />
                {!props.answerHidden && (
                    <div className={getSelectInputClass()}>
                    <select id={id} 
                        ref={input} 
                        name={props.name} 
                        value={props.value} 
                        onChange={handleChange}
                        disabled={props.disabled}>
                        {props.createFirstOption && <option value="">{firstOptionText}</option>}
                        {props.options.length &&
                            props.options.map((option) => (
                                <option value={option.value} key={props.name + '_' + option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    <div className="select--arrow"></div>
                </div>
                )}
                {(props.error && props.showErrorMessage) && <FormError error={props.error}/>}
            </label>
        </div>
    );
};

export default SelectInput;
