import React, { useState, ReactChild, ReactNode } from 'react';
import { guid } from '../../shared/lib/utils';

export interface IModal {
    title?: string;
    triggerText?: string;
    triggerClassName?: string;
    children?: ReactChild;
    footer?: ReactChild;
    openImmediatly?: boolean;
    useIcon?: boolean;
    icon?: ReactNode;
}

const Modal = (props: IModal) => {
    const [modalId] = useState<string>(`modal_${guid()}`);
    const [modalTitleId] = useState<string>(`modal_title_${guid()}`);

    return (
        <>
            {props.useIcon ? (
                <span
                    className={props.triggerClassName ? props.triggerClassName : 'btn btn--primary'}
                    data-bs-toggle="modal"
                    data-bs-target={`#${modalId}`}
                >
                    {props.icon ? props.icon : props.triggerText}
                </span>
            ) : (
                <button
                    type="button"
                    className={props.triggerClassName ? props.triggerClassName : 'btn btn--primary'}
                    data-bs-toggle="modal"
                    data-bs-target={`#${modalId}`}
                >
                    {props.triggerText ? props.triggerText : 'Vis'}
                </button>
            )}
            <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby={modalTitleId} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            {props.title && (
                                <h3 className="modal-title" id={modalTitleId}>
                                    {props.title}
                                </h3>
                            )}

                            <button type="button" className="btn btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span className="icon icon-kryss" />
                            </button>
                        </div>
                        <div className="modal-body">{props.children}</div>
                        {props.footer && <div className="modal-footer">{props.footer}</div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
