import { IInputElement, InputElementEnum } from '../../../../shared/lib/types';
import { validateInput, ValidatorTypesEnum } from '../../../../shared/lib/validators';
import {
    IKontaktperson,
    IKundeAdresse,
    IKundeFull,
    IRadgiver,
    KontaktpersonTyperEnum,
    KontaktpersonTyperNavn,
    OrganisasjonsformEnum,
} from '../../shared/lib/types';

export interface IKundeForm {
    id: string;
    navn: IInputElement;
    organisasjonsnummer: IInputElement;
    kundeansvarlig: {
        brukerId: IInputElement;
    };
    egetForetak: boolean;
    epost: IInputElement;
    mobilnummer: IInputElement;
    telefonnummer: IInputElement;
    dagligLeder: IKontaktpersonForm;
    gateadresse: IKundeAdresseForm;
    postadresse: IKundeAdresseForm;
    organisasjonsform: IInputElement;
    kontaktpersoner: Array<IKontaktpersonForm>;
    harBegrensetKundesynlighet: IInputElement;
    rettigheter: Array<string>;
}

export interface IKundeAdresseForm {
    adresse: IInputElement;
    postnummer: IInputElement;
    poststed: IInputElement;
    landkode: IInputElement;
}

export interface IKontaktpersonForm {
    id: string;
    fornavn: IInputElement;
    etternavn: IInputElement;
    epost: IInputElement;
    mobilnummer: IInputElement;
    rolle: {
        type: IInputElement;
        name: string;
    };
}

export interface IRadgiverForm {
    aktorId: string;
    fornavn: IInputElement;
    etternavn: IInputElement;
    brukerId: string;
    innloggetRadgiver?: boolean;
}

export const createKunde = (): IKundeFull => {
    let kunde = {
        id: null,
        navn: null,
        organisasjonsnummer: null,
        kundeansvarlig: {
            brukerId: null,
        },
        egetForetak: false,
        epost: null,
        mobilnummer: null,
        telefonnummer: null,
        dagligLeder: {
            id: null,
            fornavn: null,
            etternavn: null,
            epost: null,
            mobilnummer: null,
            rolle: {
                type: KontaktpersonTyperEnum.NotSet,
            },
        },
        gateadresse: {
            adresse: null,
            postnummer: null,
            poststed: null,
            landkode: null,
        },
        postadresse: {
            adresse: null,
            postnummer: null,
            poststed: null,
            landkode: null,
        },
        organisasjonsform: null,
        kontaktpersoner: [],
        harBegrensetKundesynlighet: false,
        rettigheter: [],
    } as IKundeFull;

    return kunde;
};

export const createKontaktperson = (): IKontaktperson => {
    return {
        id: null,
        fornavn: null,
        etternavn: null,
        epost: null,
        mobilnummer: null,
        rolle: {
            type: KontaktpersonTyperEnum.NotSet,
            text: null,
        },
    };
};

export const createAdresse = (): IKundeAdresse => {
    return {
        adresse: null,
        postnummer: null,
        poststed: null,
        landkode: null,
    };
};

export const mapKundeFormToKunde = (form: IKundeForm): IKundeFull => {
    let kunde = {
        id: form.id,
        navn: form.navn.value,
        organisasjonsnummer: form.organisasjonsnummer.value,
        kundeansvarlig: { brukerId: form.kundeansvarlig.brukerId.value },
        egetForetak: form.egetForetak,
        epost: form.epost.value,
        mobilnummer: form.mobilnummer.value,
        telefonnummer: form.telefonnummer.value,
        dagligLeder: mapKontaktpersonFormToKontaktperson(form.dagligLeder),
        gateadresse: mapKundeAdresseFormToKundeAdresse(form.gateadresse),
        postadresse: mapKundeAdresseFormToKundeAdresse(form.postadresse),
        organisasjonsform: form.organisasjonsform.value ?? OrganisasjonsformEnum.undefined,
        kontaktpersoner: form.kontaktpersoner.map((person, i) => mapKontaktpersonFormToKontaktperson(person)),
        harBegrensetKundesynlighet: form.harBegrensetKundesynlighet.value,
        rettigheter: form.rettigheter.map((brukerId) => {
            return { brukerId } as IRadgiver;
        }),
    } as IKundeFull;

    return kunde;
};

export const mapKundeToKundeForm = (kunde: IKundeFull): IKundeForm => {
    let kundeForm = {
        id: kunde.id,
        navn: newInputElement(kunde.navn, 'Kundenavn'),
        organisasjonsnummer: newInputElement(kunde.organisasjonsnummer, 'Organisasjonsnummer'),
        kundeansvarlig: {
            brukerId: newInputElement(kunde.kundeansvarlig?.brukerId ?? '', 'Kundeansvarlig', InputElementEnum.select, 'kundeansvarlig'),
        },
        egetForetak: kunde.egetForetak,
        epost: newInputElement(kunde.epost, 'Epost'),
        mobilnummer: newInputElement(kunde.mobilnummer, 'Mobilnummer'),
        telefonnummer: newInputElement(kunde.telefonnummer, 'Telefonnummer'),
        dagligLeder: mapKontaktpersonToKontaktpersonForm(kunde.dagligLeder, 'dagligLeder'),
        gateadresse: mapKundeAdresseToKundeAdresseForm(kunde.gateadresse, 'gateadresse'),
        postadresse: mapKundeAdresseToKundeAdresseForm(kunde.postadresse, 'postadresse'),
        organisasjonsform: newInputElement(kunde.organisasjonsform, 'Organisasjonsform', InputElementEnum.select),
        kontaktpersoner: kunde.kontaktpersoner?.map((person, i) => mapKontaktpersonToKontaktpersonForm(person, `kontaktpersoner[${i}]`)),
        harBegrensetKundesynlighet: newInputElement(kunde.harBegrensetKundesynlighet, 'Begrenset kundesynlighet', InputElementEnum.checkbox),
        rettigheter: kunde.rettigheter?.map((radgiver) => radgiver.brukerId),
    } as IKundeForm;

    // Set validators
    kundeForm.organisasjonsnummer.validators = [ValidatorTypesEnum.required, ValidatorTypesEnum.orgnr];
    kundeForm.navn.validators = [ValidatorTypesEnum.required];
    kundeForm.epost.validators = [ValidatorTypesEnum.email];
    kundeForm.telefonnummer.validators = [ValidatorTypesEnum.phone];
    kundeForm.mobilnummer.validators = [ValidatorTypesEnum.phone];
    kundeForm.dagligLeder.rolle.type.value = KontaktpersonTyperEnum.DagligLeder;

    return kundeForm;
};

export const mapKundeAdresseFormToKundeAdresse = (adresseForm: IKundeAdresseForm): IKundeAdresse => {
    return {
        adresse: adresseForm.adresse.value ?? '',
        postnummer: adresseForm.postnummer.value ?? '',
        poststed: adresseForm.poststed.value ?? '',
        landkode: adresseForm.landkode.value ?? '',
    };
};

export const mapKundeAdresseToKundeAdresseForm = (adresse: IKundeAdresse, namePrefix: string = null): IKundeAdresseForm => {
    if (!adresse) {
        adresse = createAdresse();
    }

    return {
        adresse: newInputElement(adresse.adresse, 'Gateadresse', null, namePrefix),
        postnummer: newInputElement(adresse.postnummer, 'Postnummer', null, namePrefix),
        poststed: newInputElement(adresse.poststed, 'Poststed', null, namePrefix),
        landkode: newInputElement(adresse.landkode, 'Landkode', null, namePrefix),
    } as IKundeAdresseForm;
};

export const mapKontaktpersonToKontaktpersonForm = (person: IKontaktperson = null, namePrefix: string = null): IKontaktpersonForm => {
    if (!person) {
        person = createKontaktperson();
    }

    const kontaktperson = {
        id: person.id,
        fornavn: newInputElement(person.fornavn, 'Fornavn', null, namePrefix),
        etternavn: newInputElement(person.etternavn, 'Etternavn', null, namePrefix),
        epost: newInputElement(person.epost, 'Epost', null, namePrefix),
        mobilnummer: newInputElement(person.mobilnummer, 'Mobilnummer', null, namePrefix),
        rolle: {
            type: newInputElement(person.rolle.type, 'Rolle', InputElementEnum.select, namePrefix),
            name: KontaktpersonTyperNavn[person.rolle.type],
        },
    } as IKontaktpersonForm;

    kontaktperson.fornavn.validators = [ValidatorTypesEnum.required];
    kontaktperson.etternavn.validators = [ValidatorTypesEnum.required];
    kontaktperson.epost.validators = [ValidatorTypesEnum.required, ValidatorTypesEnum.email];
    kontaktperson.mobilnummer.validators = [ValidatorTypesEnum.required, ValidatorTypesEnum.phone];

    return kontaktperson;
};

export const mapKontaktpersonFormToKontaktperson = (personForm: IKontaktpersonForm): IKontaktperson => {
    return {
        id: personForm.id,
        fornavn: personForm.fornavn.value,
        etternavn: personForm.etternavn.value,
        epost: personForm.epost.value,
        mobilnummer: personForm.mobilnummer.value,
        rolle: {
            type: parseInt(personForm.rolle.type.value),
            text: '',
        },
    };
};

export const mapAndreKontaktpersonFormToKontaktperson = (personForm: IKontaktpersonForm): IKontaktperson => {
    return {
        id: personForm.id,
        fornavn: personForm.fornavn.value,
        etternavn: personForm.etternavn.value,
        epost: personForm.epost.value,
        mobilnummer: personForm.mobilnummer.value,
        rolle: {
            type: parseInt(personForm.rolle.type.value),
            text: '',
        },
    };
};

export const mapRadgiverToKontaktperson = (radgiver: IRadgiver): IKontaktperson => {
    return {
        id: null,
        fornavn: radgiver.fornavn,
        etternavn: radgiver.etternavn,
        epost: radgiver.epost,
    } as IKontaktperson;
};

export const mapRadgiverToRadgiverForm = (radgiver: IRadgiver, namePrefix: string = null): IRadgiverForm => {
    return {
        aktorId: radgiver.aktorId,
        brukerId: radgiver.brukerId,
        fornavn: newInputElement(radgiver.fornavn, 'Fornavn', null, namePrefix),
        etternavn: newInputElement(radgiver.etternavn, 'Etternavn', null, namePrefix),
        innloggetRadgiver: radgiver.innloggetRadgiver,
    };
};

export const mapRadgiverFormToRadgiver = (radgiverForm: IRadgiverForm): IRadgiver => {
    if (
        radgiverForm.aktorId === null &&
        radgiverForm.brukerId === null &&
        radgiverForm.fornavn.value === null &&
        radgiverForm.etternavn.value === null
    ) {
        return null;
    }
    return {
        aktorId: radgiverForm.aktorId,
        brukerId: radgiverForm.brukerId,
        fornavn: radgiverForm.fornavn.value,
        etternavn: radgiverForm.etternavn.value,
        innloggetRadgiver: radgiverForm.innloggetRadgiver,
    };
};

export const newInputElement = (
    value: any,
    label: string,
    type: InputElementEnum = InputElementEnum.text,
    namePrefix: string = null,
    validators: Array<ValidatorTypesEnum> = null
): IInputElement => {
    return {
        type: type,
        label: label,
        name: inputElementName(label, namePrefix),
        value: value,
        focus: '',
        validateOnChange: false,
        validators: validators,
    } as IInputElement;
};

const inputElementName = (str: string, namePrefix: string = null): string => {
    var name = str.toLocaleLowerCase().replace(/\s/g, '_');
    return namePrefix ? `${namePrefix}.${name}` : name;
};

export const updateInput = (input: IInputElement, value: any) => {
    let updateInput = {
        ...input,
        value: value,
    };

    if (input.validators?.length && input.validateOnChange) {
        return validateInput(updateInput);
    }

    return updateInput;
};
