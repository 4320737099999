import { IInputElement } from './types';
import { removeSpaces } from './utils';

export interface IInputValidator {
    type: ValidatorTypesEnum;
    message: string;
}

export interface IValidationError {
    name: string;
    message: string;
}

export enum ValidatorTypesEnum {
    required,
    email,
    phone,
    orgnr,
}

export const minLength = (str: string | null | undefined, min: number = 1) => {
    return str && str.length >= min;
};

export const required = (str: string | null | undefined) => {
    return str && str.length > 0;
};

export const email = (str: string | null | undefined) => {
    return str && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
};

export const phone = (str: string | null | undefined) => {
    return str && /^[0-9()\-+\s]+$/.test(str);
};

export const orgnr = (str: string | null | undefined) => {
    return str && /^[0-9]{9}$/.test(removeSpaces(str));
};

const validators: Array<IInputValidator> = [
    {
        type: ValidatorTypesEnum.required,
        message: 'Feltet "{0}" må fylles ut.',
    },
    {
        type: ValidatorTypesEnum.email,
        message: 'Feltet "{0}" er ikke en gyldig epostadresse.',
    },
    {
        type: ValidatorTypesEnum.phone,
        message: 'Feltet "{0}" er ikke et gyldig telefonnummer.',
    },
    {
        type: ValidatorTypesEnum.orgnr,
        message: 'Feltet "{0}" er ikke et gyldig organisasjonsnummer.',
    },
];

export const getValidatorForType = (type: ValidatorTypesEnum, _validators: Array<IInputValidator> = validators) => {
    return _validators.find((x) => x.type === type);
};

const getMessage = (message: string, label: string) => {
    return message.replace('{0}', label);
};

export const getValidationError = (input: IInputElement): IValidationError => {
    let error = null;

    if (!input.validators) {
        return null;
    }

    if (input.validators.includes(ValidatorTypesEnum.email)) {
        if (input.value && !email(input.value)) {
            error = getValidatorForType(ValidatorTypesEnum.email, validators);
        }
    }
    if (input.validators.includes(ValidatorTypesEnum.phone)) {
        if (input.value && !phone(input.value)) {
            error = getValidatorForType(ValidatorTypesEnum.phone, validators);
        }
    }
    if (input.validators.includes(ValidatorTypesEnum.orgnr)) {
        if (input.value && !orgnr(input.value)) {
            error = getValidatorForType(ValidatorTypesEnum.orgnr, validators);
        }
    }

    if (input.validators.includes(ValidatorTypesEnum.required) && !error) {
        if (!required(input.value)) {
            error = getValidatorForType(ValidatorTypesEnum.required, validators);
        }
    }

    return error
        ? ({
              name: input.name,
              message: getMessage(error.message, input.label),
          } as IValidationError)
        : null;
};

export const isIInputValidation = (object: any): object is IInputElement => {
    return typeof object === 'object' && 'validators' in object;
};

export const validateInput = (input: IInputElement) => {
    input.validationError = getValidationError(input);
    input.validateOnChange = !!input.validationError;

    return input;
};

interface IValidationResult {
    errors: Array<IValidationError>;
    form: Object;
}

export const getValidationResult = (items: Object): IValidationResult => {
    let result = {
        errors: [],
        form: items,
    } as IValidationResult;

    if (items === null) return null;

    items = { ...items };

    Object.entries(items).forEach(([key, value]) => {
        if (value === null) return;

        if (isIInputValidation(value)) {
            let input: IInputElement = value;
            if (input) {
                input = validateInput(input);
                if (input.validationError) {
                    result.errors.push(input.validationError);
                }
                value = input;
            }
        } else if (Array.isArray(value)) {
            value.forEach((item, i) => {
                if (typeof item === 'object') {
                    const res = getValidationResult(item);
                    if (res) {
                        result.errors.push(...res.errors);
                    }
                }
            });
        } else if (typeof value === 'object') {
            const res = getValidationResult(value);
            if (res) {
                result.errors.push(...res.errors);
            }
        }
    });

    result.form = items;

    return result;
};

export default validators;
