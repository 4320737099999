interface ISpinner {
    text?: string;
    loading: boolean;
}
const Spinner = ({ loading, text }: ISpinner) => {
    if (!loading) return null;

    return (
        <div className="container">
            <div className="spinner-container">
                <p>{text}</p>
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export default Spinner;
