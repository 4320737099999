import React, { useEffect, useState, FormEvent, useRef } from 'react';
import { inputId } from '../../shared/lib/utils';
import { IInputElement, InputElementEnum } from '../../shared/lib/types';
import { ValidatorTypesEnum, IValidationError } from '../../shared/lib/validators';
import FormGroupLabelText from './FormGroupLabelText';
import FormError from './FormError';

export interface ITextInput extends IInputElement {
    required?: boolean;
    readonly?: boolean;
    answerHidden?: boolean;
    width?: string;
    validators?: Array<ValidatorTypesEnum>;
    validationError?: IValidationError;
    showErrorMessage?: boolean;
    error?: string;
}

const TextInput = (props: ITextInput) => {
    const [id] = useState(inputId());
    const input = useRef<HTMLInputElement>(null);
    const required = props.required === true || props.validators?.includes(ValidatorTypesEnum.required);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onInputChange(props.name, e.currentTarget.value);
    };

    const handleBlur = (e: FormEvent<HTMLInputElement>) => {
        props.onInputBlur && props.onInputBlur(props.name);
    };
    function getClass() {
        let className = 'form-group form-group--text';

        if (props.validationError) {
            className += ' form-group--error';
        }

        if (props.width) {
            className += ` form-group--${props.width}`;
        }

        return className;
    }

    function getInputClass() {
        let className = 'form-group__input';

        if (props.disabled || props.readonly) {
            className += ' disabled';
        }

        if (props.error) {
            className += ' error-input';
        }

        return className;
    }

    function getType() {
        if (props.type === InputElementEnum.email) return 'email';
        if (props.type === InputElementEnum.phone) return 'tel';
        return 'text';
    }

    useEffect(() => {
        if (props.focus === props.name) {
            input?.current?.focus();
        }
    }, [props.focus, props.name]);

    return (
        <div className={getClass()}>
            <label htmlFor={id}>
                <FormGroupLabelText label={`${props.label}${required ? '*' : ''}`} helpText={props.helpText} />
                {props.disabled || props.readonly ? (
                    <div className="form-group__input disabled-textinput">{props.value}</div>
                ) : (
                    <div className="search">
                        <input
                            type={getType()}
                            id={id}
                            ref={input}
                            className={getInputClass()}
                            name={props.name}
                            aria-required={required}
                            aria-invalid={!!props.validationError}
                            required={required}
                            readOnly={props.readonly}
                            value={props.value ?? ''}
                            placeholder={props.placeholder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={props.disabled || props.readonly}
                            hidden={props.answerHidden}
                        />
                        <div className="search__addon"></div>
                        {props.error && props.showErrorMessage && <FormError error={props.error} />}
                    </div>
                )}
                <span className="form-group__validation-error">{props.validationError && props.validationError.message}</span>
            </label>
        </div>
    );
};

export default TextInput;
