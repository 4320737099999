export const fieldHasMatch = (
    value: string | undefined,
    qArr: Array<string> = []
): boolean => {
    if (value && qArr.length) {
        let match = false;

        qArr.forEach((q) => {
            if (value.includes(q)) {
                match = true;
            }
        });

        return match;
    }
    return true;
};

export const markMatch = (
    value: string | undefined,
    qArr: Array<string> = []
): string => {
    let valArr = value?.split(' ') || [];

    if (valArr.length && qArr.length) {
        valArr = valArr.map((val) => {
            qArr.forEach((q) => {
                // make sure we dont mark <mark>'s
                if (val.includes('<mark>')) {
                    q = q
                        .replace('mark', '')
                        .replace('mar', '')
                        .replace('ma', '');
                    if (q.length === 0) {
                        return;
                    }
                }

                const start = val.toLowerCase().indexOf(q);

                if (start !== -1) {
                    let match = val.slice(start, start + q.length);
                    let mark = `<mark>${match}</mark>`;
                    val = val.replace(new RegExp(match, 'ig'), mark);
                }
            });

            return val;
        });
    }

    return valArr.join(' ');
};
