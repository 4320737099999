import React from 'react';
import { InputElementEnum } from '../../../../shared/lib/types';
import CheckboxGroup from '../../../form/CheckboxGroup';
import { ICheckboxInput } from '../../../form/CheckboxInput';
import Modal from '../../../modal/Modal';
import { IRadgiver } from '../../shared/lib/types';
import { getRadgiverFullName } from '../kundeHelper';
import { newInputElement } from './kundeFormHelper';

export interface IRettigheter {
    radgivere: Array<IRadgiver>;
    kundeansvarlig: IRadgiver;
    rettigheter: Array<string>;
    onInputChange(rettigheter: Array<string>): void;
}
const MAX_ITEM = 4;
const Rettigheter = (props: IRettigheter) => {
    const getSummary = (): Array<string> => {
        return props.rettigheter.map((brukerId) => {
            const radgiver = props.radgivere.find((radgiver) => radgiver.brukerId === brukerId);
            return getRadgiverFullName(radgiver);
        });
    };

    const getRadgivereInputs = () => {
        const radgivere = props.radgivere.filter((x) => x.brukerId !== props.kundeansvarlig.brukerId);
        return radgivere.map((radgiver, i) =>
            newInputElement(radgiver.brukerId, getRadgiverFullName(radgiver), InputElementEnum.checkbox, `rettigheter[${i}]`)
        );
    };

    return (
        <div>
            <ul>
                <li>{`${getRadgiverFullName(props.kundeansvarlig, false)} (kundeansvarlig)`}</li>
                {getSummary()
                    .slice(0, MAX_ITEM)
                    .map((name) => {
                        return <li key={name}>{name}</li>;
                    })}
                {getSummary().length > MAX_ITEM && (
                    <li>
                        <span>...og {getSummary().length - MAX_ITEM} personer til</span>
                    </li>
                )}
            </ul>

            <Modal
                triggerText="Velg rådgivere"
                triggerClassName="btn btn--secondary btn--sm mb-lg"
                title="VELG PERSONER MED TILGANG"
                footer={
                    <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                        Lukk
                    </button>
                }
                children={
                    <>
                        <p>{`${getRadgiverFullName(props.kundeansvarlig, false)} (kundeansvarlig)`}</p>
                        <div className="row">
                            <div className="col-md-6 d-md-flex justify-content-start">
                                <button className="btn btn--primary--underline" onClick={() => {
                                    const radgivere = getRadgivereInputs() as Array<ICheckboxInput>;
                                    const selectedRadgivere = radgivere.map((item) => item.value);
                                    props.onInputChange(selectedRadgivere);
                                    
                                }}>Merk alle</button>
                            </div>
                            <div className="col-md-6 d-md-flex justify-content-center">
                                <button
                                    className="btn btn--primary--underline"
                                    onClick={() => {
                                        props.onInputChange([]);
                                    }}
                                >
                                    Merk ingen
                                </button>
                            </div>
                        </div>
                        <div>
                            <CheckboxGroup
                                legend=""
                                label="Rettigheter"
                                type={InputElementEnum.checkboxGroup}
                                inputs={getRadgivereInputs() as Array<ICheckboxInput>}
                                onInputChange={(name, value) => {
                                    props.onInputChange(value);
                                }}
                                focus=""
                                name="rettigheter"
                                value={props.rettigheter}
                            />
                        </div>
                    </>
                }
            ></Modal>
        </div>
    );
};

export default Rettigheter;
