import React from 'react';
import { validateInput } from '../../../shared/lib/validators';
import TextInput from '../../form/TextInput';
import { IKundeAdresseForm, updateInput } from '../kunder/kundedetaljer/kundeFormHelper';

export interface IBedriftAdresseProps {
    adresse: IKundeAdresseForm;
    onValueChange(adresse: IKundeAdresseForm): void;
}

const BedriftAdresse = (props: IBedriftAdresseProps) => {
    return (
        <div className="bedrift-adresse">
            {props.adresse && (
                <>
                    <TextInput
                        {...props.adresse.adresse}
                        onInputChange={(name, value) => {
                            props.onValueChange({
                                ...props.adresse,
                                adresse: updateInput(props.adresse.adresse, value),
                            });
                        }}
                        onInputBlur={(name) => {
                            props.onValueChange({
                                ...props.adresse,
                                postnummer: validateInput(props.adresse.postnummer),
                            });
                        }}
                    />
                    <div className="row">
                        <div className="col-md-4">
                            <TextInput
                                {...props.adresse.postnummer}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.adresse,
                                        postnummer: updateInput(props.adresse.postnummer, value),
                                    });
                                }}
                            />
                        </div>
                        <div className="col-md-8">
                            <TextInput
                                {...props.adresse.poststed}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.adresse,
                                        poststed: updateInput(props.adresse.poststed, value),
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <TextInput
                                {...props.adresse.landkode}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.adresse,
                                        landkode: updateInput(props.adresse.landkode, value),
                                    });
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BedriftAdresse;
