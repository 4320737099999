import {
    IVeilederliste,
    IVeilederStep,
    IKunde,
    IKundeFull,
    IRadgiver,
    IKontaktpersonRolle,
    IKontaktperson,
    IVeileder,
    IActiveVeilederApiResponse,
    IBruker,
    IVeilederIArbeid,
    IDokumentStatistikk,
    IKundeLinkItem,
    DokumentOversikt,
    Dokument,
    IFlags,
} from './types';

export const radgivereMock = [
    {
        aktorId: '40583577',
        fornavn: 'Guro Trønnes',
        etternavn: 'Duberg',
        brukerId: 'bJezVdCXzUymSg0Z2oa5qQ',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '40473399',
        fornavn: 'Kristin',
        etternavn: 'Havness',
        brukerId: 'nBvXoCD6X0iNIQ5Zpzytkw',
        innloggetRadgiver: false,
        epost: 'test2@test.com',
    },
    {
        aktorId: '50017235',
        fornavn: 'Sandra Mellem',
        etternavn: 'Enoksen',
        brukerId: 'b3AJFfIW10mIaRCw3D_2Dg',
        innloggetRadgiver: false,
        epost: 'test3@test.com',
    },
    {
        aktorId: '40430340',
        fornavn: 'Geir',
        etternavn: 'Sandvik',
        brukerId: 'mbZ53-Ceu0G9ASB0h_BoDA',
        innloggetRadgiver: false,
        epost: 'test4@test.com',
    },
    {
        aktorId: '50022913',
        fornavn: 'Endre',
        etternavn: 'Gjølstad',
        brukerId: 'Ts9zUmfxkEqhTyIYkBpghg',
        innloggetRadgiver: false,
        epost: 'test5@test.com',
    },
    {
        aktorId: '50022862',
        fornavn: 'Inger',
        etternavn: 'Norwich',
        brukerId: 'Tqu3wkdbeUy1Vi4bHFwmnw',
        innloggetRadgiver: false,
        epost: 'test6@test.com',
    },
    {
        aktorId: '40497299',
        fornavn: 'Anita',
        etternavn: 'Nilsen',
        brukerId: 'XKXdZlccRk2-LzxsdBNL-g',
        innloggetRadgiver: false,
        epost: 'test7@test.com',
    },
    {
        aktorId: '70000302',
        fornavn: 'grand',
        etternavn: 'grand',
        brukerId: 'CsWWf69c8EeD00etS3MGxQ',
        innloggetRadgiver: false,
        epost: 'test8@test.com',
    },
    {
        aktorId: '50016544',
        fornavn: 'Lene Naustvik',
        etternavn: 'Sundalskleiv',
        brukerId: '_XDKvWcqy0CaPU3WdS93vQ',
        innloggetRadgiver: false,
        epost: 'test9@test.com',
    },
    {
        aktorId: '70000281',
        fornavn: 'Test Annen',
        etternavn: 'Virksomhet',
        brukerId: 'usvQzpAamUSRj2LFcTCXLQ',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '50021480',
        fornavn: 'Olaf',
        etternavn: 'Torske',
        brukerId: 'g18h8iosgUihbWu9AkvyNQ',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '40586455',
        fornavn: 'Ragnhild Skahjem',
        etternavn: 'Tofastrud',
        brukerId: 'gqKd-EV0-E6O8JC1sPUnLw',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '70000297',
        fornavn: 'opeth',
        etternavn: 'opeth',
        brukerId: 'Eb4e8ShLk06gEZpw3nxhEQ',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '70000296',
        fornavn: 'epostmannen',
        etternavn: 'epostnavnet',
        brukerId: 'zNRLsav3sUybIJ-IyYy4JQ',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '50013237',
        fornavn: 'Gerda',
        etternavn: 'Grønn',
        brukerId: 'v-CaNWDPj0KrxKL27Te4kQ',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '70000295',
        fornavn: 'Arbeidsmannen',
        etternavn: 'Arbeidsnavnet',
        brukerId: 'a2KlMQzgwE6LvKYWi9wcNA',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '70000301',
        fornavn: 'bleak',
        etternavn: 'bleak',
        brukerId: 'AF-oPpu0SUCTYbbmC4jADg',
        innloggetRadgiver: false,
        epost: 'test@test.com',
    },
    {
        aktorId: '50022863',
        fornavn: 'Marte ',
        etternavn: 'Hatle',
        brukerId: 'WmF29n7kZUWBRrcLauNU9Q',
        innloggetRadgiver: false,
    },
    {
        aktorId: '70000298',
        fornavn: 'perdition',
        etternavn: 'perdition',
        brukerId: 'gOgqkvlUBUKJ6b6t2Ciw1Q',
        innloggetRadgiver: false,
    },
    {
        aktorId: '50016139',
        fornavn: 'Christian',
        etternavn: 'Stokkebekk-Bøhm',
        brukerId: 'NBDIYjOQ6USZcMPAju2EqQ',
        innloggetRadgiver: false,
    },
    {
        aktorId: '70000300',
        fornavn: 'deliverance',
        etternavn: 'deliverance',
        brukerId: 'cKiZ4PUB7kC6aMP9XyX5kw',
        innloggetRadgiver: false,
    },
    {
        aktorId: '50019134',
        fornavn: 'Jonas Jahren',
        etternavn: 'Rosnes',
        brukerId: 'ERFPeIa3ZU2wEcTYZyndvQ',
        innloggetRadgiver: false,
    },
    {
        aktorId: '40550980',
        fornavn: 'Maren K.',
        etternavn: 'Areklett',
        brukerId: '_wZvazZQl0mBZskYM3vUDg',
        innloggetRadgiver: false,
    },
    {
        aktorId: '40376378',
        fornavn: 'Svein',
        etternavn: 'Austheim',
        brukerId: 'XHmoPeulkUiTtctK3MsKvg',
        innloggetRadgiver: false,
    },
    {
        aktorId: '70000335',
        fornavn: 'Knowit',
        etternavn: 'Tester',
        brukerId: 'b_s5I-qo20CdMsxmTBdRmw',
        innloggetRadgiver: true,
    },
    {
        aktorId: '50003831',
        fornavn: 'Oksana',
        etternavn: 'Mikhalchuk',
        brukerId: 'NsAifCjE7UG959Na_gbJ5g',
        innloggetRadgiver: false,
    },
    {
        aktorId: '50010747',
        fornavn: 'Andreas',
        etternavn: 'Halvorsen',
        brukerId: 'KlG1o_A1iEW8w9nR_Vv-mQ',
        innloggetRadgiver: false,
    },
    {
        aktorId: '50023236',
        fornavn: 'May-Lisbeth',
        etternavn: 'Norum',
        brukerId: 'sSIKZAoXR0mVNNs-1snmRA',
        innloggetRadgiver: false,
    },
    {
        aktorId: '40546889',
        fornavn: 'Hans Christian',
        etternavn: 'Ellefsen',
        brukerId: 'jAd0Dd_Ocki0jOwWDMWQqQ',
        innloggetRadgiver: false,
    },
    {
        aktorId: '70000299',
        fornavn: 'harvest',
        etternavn: 'harvest',
        brukerId: 'XZDDU2naWkGNO-3JgTLxVA',
        innloggetRadgiver: false,
    },
] as Array<IRadgiver>;

export const innloggetbrukerMock = {
    aktorId: '70000299',
    fornavn: 'Knowit2 Tester',
    etternavn: 'Tester',
    brukerId: 'c8-92X3N6kq2XFC7V6F6IQ',
    regnskapsforetakId: 'xfDynnVNyE-VVwwZJQp0OQ',
    regnskapsforetakOrganisasjonsnummer: '930044458',
    ksDebugger: true,
} as IBruker;
export const digitalsigneringMock = true as boolean;
export const dokumenterForVeileder = [
    {
        id: 'LrFJQWKbOkSMnSqeiRRfUw',
        dokumentMappeId: 't17c1B2cMUuIC40bfzCpVQ',
        tittel: 'Endringsoversikt',
        mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        dokumenttype: 'docx',
        filstorrelseInKB: 10.341,
        status: {
            type: 2,
            text: 'Kladd',
        },
        gjeldendeFra: '2021-05-05T08:46:25.4333333+00:00',
        gjeldendeTil: null,
        opprettetUtc: '2021-05-05T08:42:54.7133333+00:00',
        sistOppdatertUtc: '2021-05-05T08:46:25.45+00:00',
        parentPdfId: null,
        signeringsstatus: {
            type: 0,
            text: 'Ingen signeringsstatus',
        },
    },
] as Array<Dokument>;

export const dokumenterForKunde = [
    {
        id: '5OTJsWJy1k6cSHx1hxnSTw',
        navn: 'Databehandleravtale',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'DgPy9fD80UOQG1RHCk85iQ',
                dokumentMappeId: '5OTJsWJy1k6cSHx1hxnSTw',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.5625,
                status: {
                    type: 2,
                    text: 'Gjeldende',
                },
                gjeldendeFra: '2023-09-28T19:18:15.598892+00:00',
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-09-28T19:13:34.736494+00:00',
                sistOppdatertUtc: '2023-09-28T19:18:15.5988924+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'mZCzPKp6pEa5oRdV5ckSZQ',
                dokumentMappeId: '5OTJsWJy1k6cSHx1hxnSTw',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.49219,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2022-03-23T12:07:38.6165715+00:00',
                gjeldendeTil: '2023-09-28T19:18:15.5974747+00:00',
                opprettetUtc: '2022-03-23T12:06:53.2350168+00:00',
                sistOppdatertUtc: '2023-09-28T19:18:15.5974818+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'b-3zQd1MkECd87JhvGsmXQ',
                dokumentMappeId: '5OTJsWJy1k6cSHx1hxnSTw',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.49219,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2022-03-23T07:43:09.8602216+00:00',
                gjeldendeTil: '2022-03-23T12:07:38.615211+00:00',
                url: null,
                opprettetUtc: '2022-03-23T07:43:03.3680175+00:00',
                sistOppdatertUtc: '2022-03-23T12:07:38.6152129+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'Qmi5fNC1SUOKdcU7G_MXFQ',
                dokumentMappeId: '5OTJsWJy1k6cSHx1hxnSTw',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.49219,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2022-03-23T07:42:28.7508989+00:00',
                gjeldendeTil: '2022-03-23T07:43:09.8580296+00:00',
                url: null,
                opprettetUtc: '2022-03-23T07:42:26.4123474+00:00',
                sistOppdatertUtc: '2022-03-23T07:43:09.8580315+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'KEm2PS3ksEG6F3kBRXCWrA',
        navn: 'Egenerklæring - fysisk person',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'Bu-TTHrHPESZfHCSHuMmCg',
                dokumentMappeId: 'KEm2PS3ksEG6F3kBRXCWrA',
                tittel: 'Egenerklæring - fysisk person',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.51465,
                status: {
                    type: 2,
                    text: 'Gjeldende',
                },
                gjeldendeFra: '2022-09-05T12:33:52.3204576+00:00',
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2022-09-05T12:33:09.1691022+00:00',
                sistOppdatertUtc: '2022-09-05T12:33:52.3204582+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'Zixz-PaWOkGFXFYkeB7nsw',
                dokumentMappeId: 'KEm2PS3ksEG6F3kBRXCWrA',
                tittel: 'Egenerklæring - fysisk person',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.16992,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2022-03-23T07:34:48.41814+00:00',
                gjeldendeTil: '2022-09-05T12:33:52.2935859+00:00',
                url: null,
                opprettetUtc: '2022-03-23T07:34:44.279502+00:00',
                sistOppdatertUtc: '2022-09-05T12:33:52.2935866+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'CAJEAlYLu0iHF0ShSVTFIg',
        navn: 'Fullmakt – dialog Skatteetaten',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'Vw7IdxYCK0OdGfnZ7Oerig',
                dokumentMappeId: 'CAJEAlYLu0iHF0ShSVTFIg',
                tittel: 'Fullmakt – kontakt Skattemyndigheter',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 13.57617,
                status: {
                    type: 2,
                    text: 'Gjeldende',
                },
                gjeldendeFra: '2023-08-10T10:41:57.1680627+00:00',
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-10T10:40:36.0688266+00:00',
                sistOppdatertUtc: '2023-08-10T10:41:57.1680639+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'RDXgI_kFP0-xtw5TimIVKQ',
        navn: 'Ny Altinn-fullmakt',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'SQMGmyvCwUOEFDtaKQGfig',
                dokumentMappeId: 'RDXgI_kFP0-xtw5TimIVKQ',
                tittel: 'Ny Altinn-fullmakt - engelsk',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.52734,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2019-08-19T06:09:45.177+00:00',
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2019-08-19T06:09:41.68+00:00',
                sistOppdatertUtc: '2019-08-19T06:09:45.193+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'l759LodxgUOmQB_3W9lVHw',
                dokumentMappeId: 'RDXgI_kFP0-xtw5TimIVKQ',
                tittel: 'Ny Altinn-fullmakt - engelsk',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.52929,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2019-08-16T10:24:50.623+00:00',
                gjeldendeTil: '2019-08-19T06:09:45.177+00:00',
                url: null,
                opprettetUtc: '2019-08-16T10:24:47.907+00:00',
                sistOppdatertUtc: '2019-08-19T06:09:45.193+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'U1-qcmuBjkW1wOB--zP2Mg',
                dokumentMappeId: 'RDXgI_kFP0-xtw5TimIVKQ',
                tittel: 'Ny Altinn-fullmakt - norsk',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 15.27734,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2019-08-16T10:24:50.623+00:00',
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2019-08-16T10:24:47.343+00:00',
                sistOppdatertUtc: '2019-08-16T10:24:50.653+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'gRcnRD5-vEWZ79ozAK6XdA',
                dokumentMappeId: 'RDXgI_kFP0-xtw5TimIVKQ',
                tittel: 'Ny Altinn-fullmakt - norsk',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 15.29394,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2019-08-16T10:13:07.793+00:00',
                gjeldendeTil: '2019-08-16T10:24:50.623+00:00',
                url: null,
                opprettetUtc: '2019-08-16T10:13:03.973+00:00',
                sistOppdatertUtc: '2019-08-16T10:24:50.653+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'SoDp3lSj40K4TEUVK5Ll9A',
        navn: 'Ønsker ikke gi tilbud - brev',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'xSKq5e-rXkyC0zRWQIc3-Q',
                dokumentMappeId: 'SoDp3lSj40K4TEUVK5Ll9A',
                tittel: 'Ønsker ikke gi tilbud - brev',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 14.02148,
                status: {
                    type: 2,
                    text: 'Gjeldende',
                },
                gjeldendeFra: '2022-03-23T07:36:15.0172194+00:00',
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2022-03-23T07:35:37.4221315+00:00',
                sistOppdatertUtc: '2022-03-23T07:36:15.0172215+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'U8J2kny6yUGGepUblJzNGg',
        navn: 'Oppdragsavtale med bilag',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'KQOzczKfEUeyPrxMn6Ef3w',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Endringsoversikt',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 13.02832,
                status: {
                    type: 2,
                    text: 'Gjeldende',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:10.2459524+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:10.2459525+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 2,
                    text: 'Signert',
                },
            },
            {
                id: 'yXf85FL28EGcaZfnn1OD-g',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Bruk av IT-systemer i fellesskap',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 23.43848,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:08.5207992+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:08.5207993+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'KhdxE7i9-UKA55_j95haPA',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.48926,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:07.2571294+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:07.2571295+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '59E10_HgkkeT__77LoGWDQ',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Honorar og betalingsbetingelser',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 29.80469,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:05.7255119+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:05.725512+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'iSydhXwEykGG0QxpiYF2zA',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Oppdragspesifikasjon',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 25.45508,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:04.4796384+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:04.4796385+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'eA7hSI1M6Ei3IuO8Cp47sw',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Standard leveransevilkår for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 38.10938,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:03.0701163+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:03.0701165+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '_kW-YXEZd0amKORNTBedqg',
                dokumentMappeId: 'U8J2kny6yUGGepUblJzNGg',
                tittel: 'Oppdragsavtale for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 24.20898,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-08-25T10:55:01.459011+00:00',
                sistOppdatertUtc: '2023-08-25T10:55:01.4590112+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'A_mC7f6V6U2V_xz-tPFxbA',
        navn: 'Varsling - rutine',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: 'Snz33IlxB0WHJQ9IfYhMsQ',
                dokumentMappeId: 'A_mC7f6V6U2V_xz-tPFxbA',
                tittel: 'Varslingsrutine',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.91016,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-09-15T12:29:15.3084557+00:00',
                sistOppdatertUtc: '2023-09-15T12:29:15.3084558+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '7VSER9kB70KU8qe4UhuOJQ',
                dokumentMappeId: 'A_mC7f6V6U2V_xz-tPFxbA',
                tittel: 'Varslingsrutine',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.91016,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-06-22T10:18:05.5737538+00:00',
                sistOppdatertUtc: '2023-09-15T12:29:13.0374003+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'WpUpy4BJAUeKICTi3X-ilw',
                dokumentMappeId: 'A_mC7f6V6U2V_xz-tPFxbA',
                tittel: 'Varslingsrutine',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.91113,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: '2022-11-14T08:08:54.7260187+00:00',
                gjeldendeTil: '2022-11-14T08:13:41.2804882+00:00',
                url: null,
                opprettetUtc: '2022-11-14T07:52:48.0878579+00:00',
                sistOppdatertUtc: '2022-11-14T08:13:41.2804901+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'urAdsocoFUOCaQJrRFwI4A',
                dokumentMappeId: 'A_mC7f6V6U2V_xz-tPFxbA',
                tittel: 'Varslingsrutine',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.91113,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2022-09-01T10:47:01.490064+00:00',
                sistOppdatertUtc: '2022-11-14T07:52:46.4000608+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '679gY2JzzU2qod1gspELCQ',
                dokumentMappeId: 'A_mC7f6V6U2V_xz-tPFxbA',
                tittel: 'Varslingsrutine',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 17.91113,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2022-08-31T08:57:49.6688186+00:00',
                sistOppdatertUtc: '2022-09-01T10:47:00.4854295+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'ZwPr09lAq0Oa0-shujl5tQ',
        navn: 'Oppdragsavtale med bilag',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [
            {
                id: '6cbRblMeoEKAmGPMLdDlyQ',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragsavtale med bilag - hele dokumentet',
                mimetype: 'application/pdf',
                dokumenttype: 'pdf',
                filstorrelseInKB: 162.64551,
                status: {
                    type: 2,
                    text: 'Gjeldende',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-11-01T19:05:57.3085773+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.3085775+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 2,
                    text: 'Signert',
                },
            },
            {
                id: 'u-taa83bVEemaEFPZoikaA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragsavtale med bilag - hele dokumentet',
                mimetype: 'application/pdf',
                dokumenttype: 'pdf',
                filstorrelseInKB: 162.64551,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-11-01T19:04:28.2782483+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.3038329+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'YpDyu0lCwUe2t3YIv9BEVg',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragsavtale med bilag - hele dokumentet',
                mimetype: 'application/pdf',
                dokumenttype: 'pdf',
                filstorrelseInKB: 162.64551,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '2023-11-01T19:03:12.6794215+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:28.2729211+00:00',
                parentPdfId: null,
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'GhjVJleGSUSaiAOcosKaHA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Standard leveransevilkår for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 38.10938,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:16.9004541+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'NNgiZzW3hk-_cAXD1KXmwQ',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Bruk av IT-systemer i fellesskap',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 22.82422,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.4953833+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'BIuF-oAZ6k-tKy4EWVowvg',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Standard leveransevilkår for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 38.10938,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:46.6526676+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'kSR4yW8bY0yAZjKEeOn44g',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Bruk av IT-systemer i fellesskap',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 22.82422,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:51.5540703+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'gtyR6zp1TkOJlDPoKtWjkQ',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Honorar og betalingsbetingelser',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 29.72852,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.4409717+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '3JXE1cMYFUK3ZT7nPEGWHQ',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Bruk av IT-systemer i fellesskap',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 22.82422,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:22.7044478+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'nUX-8O6obEOIGU7X36Vv8w',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.28027,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:50.2979555+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'xe9SiP5EWkqJz2S8wlbsKA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragsavtale for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 20.58789,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:45.5315487+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'wTmctgmVnEiJuXLlO6A1RA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Endringsoversikt',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 13.18555,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.5264938+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'fmxVoTQTXkq87IiWr-zy2w',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Honorar og betalingsbetingelser',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 29.72852,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:19.9418793+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'JkWujYrbCEOev476IpbXoA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragsavtale for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 20.58789,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.3510344+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'JN6XkfbPoEavv51aUOxyhA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragspesifikasjon',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 27.58691,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:48.188993+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '2oQf9ThcTk2nZqzKR_SNVw',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Endringsoversikt',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 13.18555,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:23.5293068+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '_dUs91xgQUG79LcV3FgW6A',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragspesifikasjon',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 27.58789,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:18.4647157+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '-gk7RvlYz0279r4H5Eu3wQ',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.28027,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:21.5297989+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'TQRdKwRjm02daL4WnykmGA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Endringsoversikt',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 13.18555,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:56.2206896+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'RzUyMs3jsES63euo_88akg',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragsavtale for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 20.58789,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:04:15.424208+00:00',
                parentPdfId: 'YpDyu0lCwUe2t3YIv9BEVg',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'EfXhym8odEaaou4Zu0_wGA',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Databehandleravtale',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 26.28027,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.4702033+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'GkLsYsGOGU60ie-U1cXxsQ',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Honorar og betalingsbetingelser',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 29.72852,
                status: {
                    type: 3,
                    text: 'Tidligere',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:49.1194984+00:00',
                parentPdfId: 'u-taa83bVEemaEFPZoikaA',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: 'l7JAGytD0EmByfj566r90Q',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Standard leveransevilkår for regnskapsoppdrag',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 38.10938,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.3792567+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
            {
                id: '49WGCPHoLkm18f5BCaUvMg',
                dokumentMappeId: 'ZwPr09lAq0Oa0-shujl5tQ',
                tittel: 'Oppdragspesifikasjon',
                mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                dokumenttype: 'docx',
                filstorrelseInKB: 27.58594,
                status: {
                    type: 1,
                    text: 'Kladd',
                },
                gjeldendeFra: null,
                gjeldendeTil: null,
                url: null,
                opprettetUtc: '0001-01-01T00:00:00+00:00',
                sistOppdatertUtc: '2023-11-01T19:05:57.4092069+00:00',
                parentPdfId: '6cbRblMeoEKAmGPMLdDlyQ',
                signeringsstatus: {
                    type: 0,
                    text: 'Ingen signeringsstatus',
                },
            },
        ],
    },
    {
        id: 'x7Hmki6z_ECtHBb-RG80SA',
        navn: 'Test av Report',
        opprettetUtc: '2021-05-05T08:46:25.45+00:00',
        dokumenter: [],
    },
] as Array<DokumentOversikt>;

export const dokumentstatistikkMock = {
    antallDokumenter: 1,
} as IDokumentStatistikk;

export const kundelisteMock = [
    {
        navn: 'Data Frisør Tariq Mahmood',
        organisasjonsnummer: '892 988 332',
        id: 'xfDynnVNyE-VVwwZJQp0OQ',
        kundeansvarlig: {
            fornavn: 'Gorm',
            etternavn: 'Pettersen',
            aktorId: 'gorm_1234',
            brukerId: '1234',
            innloggetRadgiver: false,
        },
        egetForetak: true,
    } as IKunde,

    {
        navn: 'Arne Bjørnvold AS',
        organisasjonsnummer: '876268442',
        id: '876268442',
        kundeansvarlig: {
            fornavn: 'Skrue',
            etternavn: 'McDuck',
            aktorId: 'skrue_1234',
            brukerId: '2345',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,

    {
        navn: 'Regnskapet AS',
        organisasjonsnummer: '982 232 233',
        id: '982232233',
        kundeansvarlig: {
            fornavn: 'Donald',
            etternavn: 'Duck',
            aktorId: 'donald_1234',
            brukerId: '3456',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,
    {
        navn: 'Telenor',
        organisasjonsnummer: '914742315',
        id: '914742315',
        kundeansvarlig: {
            fornavn: 'Dolly',
            etternavn: 'Duck',
            aktorId: 'dolly_1234',
            brukerId: '4567',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,
    {
        navn: 'Knowit',
        organisasjonsnummer: '432 659 881',
        id: '432659881',
        kundeansvarlig: {
            fornavn: 'Dolly',
            etternavn: 'Duck',
            aktorId: 'dolly_1234',
            brukerId: '4567',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,
    {
        navn: 'Regnskap Norge AS',
        organisasjonsnummer: '985196087',
        id: '985196087',
        kundeansvarlig: {
            fornavn: 'Gorm',
            etternavn: 'Pettersen',
            aktorId: 'gorm_1234',
            brukerId: '1234',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,
    {
        navn: 'Brødrene Dahl A/S',
        organisasjonsnummer: '945757280',
        id: '945757280',
        kundeansvarlig: {
            fornavn: 'Gorm',
            etternavn: 'Pettersen',
            aktorId: 'gorm_1234',
            brukerId: '1234',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,
    {
        navn: 'Bekkestua Sushi & Wok AS',
        organisasjonsnummer: '921514972',
        id: '921514972',
        kundeansvarlig: {
            fornavn: 'Mikke',
            etternavn: 'Mus',
            aktorId: 'mikke_123',
            brukerId: '5678',
            innloggetRadgiver: false,
        },
        egetForetak: false,
    } as IKunde,
];

export const veiledereIArbeid = [
    {
        tittel: 'GAMMEL - Avslutning oppdrag - oppsigelse av oppdrag',
        sistOppdatertUtc: '2021-12-14T09:22:23.5399264+00:00',
        veilederId: '1001',
    },
    {
        tittel: 'b) Databehandleravtale',
        sistOppdatertUtc: '2021-12-14T09:22:23.5399264+00:00',
        veilederId: '1002',
    },
    {
        tittel: 'a) Oppdragsavtale med bilag',
        sistOppdatertUtc: '2021-12-14T09:22:23.5399264+00:00',
        veilederId: '1003',
    },
] as Array<IVeilederIArbeid>;

export const kundeMock = {
    epost: 'js@gmail.com',
    mobilnummer: '93431551',
    telefonnummer: null,
    dagligLeder: {
        id: 'A7t_hnHhpEGpmC62YqW5fQ',
        fornavn: 'Johan',
        etternavn: 'Stene',
        epost: 'johan.stene@gmail.com',
        mobilnummer: '93431551',
        rolle: {
            type: 5,
            text: null,
        } as IKontaktpersonRolle,
    } as IKontaktperson,
    gateadresse: {
        adresse: null,
        postnummer: null,
        poststed: null,
        landkode: null,
    },
    postadresse: {
        adresse: null,
        postnummer: null,
        poststed: null,
        landkode: null,
    },
    organisasjonsform: 12,
    kontaktpersoner: [
        {
            id: 'A7t_hnHhpEGpmC62YqW5f1',
            fornavn: 'Johan1',
            etternavn: 'Stene1',
            epost: 'johan1.stene@gmail.com',
            mobilnummer: '93431551',
            rolle: {
                type: 4,
                text: null,
            } as IKontaktpersonRolle,
        },
        {
            id: 'A7t_hnHhpEGpmC62YqW5f2',
            fornavn: 'Johan2',
            etternavn: 'Stene2',
            epost: 'johan2.stene@gmail.com',
            mobilnummer: '93431551',
            rolle: {
                type: 3,
                text: null,
            } as IKontaktpersonRolle,
        },
        {
            id: 'A7t_hnHhpEGpmC62YqW5f3',
            fornavn: 'Johan3',
            etternavn: 'Stene3',
            epost: 'johan3.stene@gmail.com',
            mobilnummer: '93431551',
            rolle: {
                type: 2,
                text: null,
            } as IKontaktpersonRolle,
        },
    ],
    harBegrensetKundesynlighet: false,
    rettigheter: [],
    id: 'NMzqWDyqi0KCFIdcWCjZ0Q',
    navn: 'Johan Konsult',
    organisasjonsnummer: '555555555',
    egetForetak: false,
    kundeansvarlig: {
        aktorId: '70000335',
        fornavn: 'Knowit',
        etternavn: 'Tester',
        brukerId: 'b_s5I-qo20CdMsxmTBdRmw',
        innloggetRadgiver: true,
    } as IRadgiver,
} as IKundeFull;

export const kundeMock2 = {
    epost: 'beathe@fosberg.no',
    mobilnummer: '92204031',
    telefonnummer: '',
    dagligLeder: {
        id: null,
        fornavn: 'Beathe Katrin',
        etternavn: 'Fosberg',
        epost: null,
        mobilnummer: '92204031',
        rolle: {
            type: 5,
            text: null,
        },
    },
    gateadresse: {
        adresse: 'Lyngveien 40013',
        postnummer: '1825',
        poststed: 'Tomter',
        landkode: 'NO',
    },
    postadresse: null,
    organisasjonsform: 12,
    kontaktpersoner: [],
    harBegrensetKundesynlighet: false,
    rettigheter: [],
    id: null,
    navn: 'Fosberg Strikk & Hobby',
    organisasjonsnummer: '916258771',
    kundeansvarlig: null,
    egetForetak: false,
} as IKundeFull;

export const kundeMockNull = {
    epost: null,
    mobilnummer: null,
    telefonnummer: null,
    dagligLeder: null,
    gateadresse: null,
    postadresse: null,
    organisasjonsform: 12,
    kontaktpersoner: null,
    harBegrensetKundesynlighet: false,
    rettigheter: null,
    id: null,
    navn: 'Jeg er et null',
    organisasjonsnummer: null,
    kundeansvarlig: null,
    egetForetak: false,
} as IKundeFull;

export const veilederListeMock = {
    hovedKategorier: [
        {
            navn: '1. oppdrag - avtale og fullmakter',
            underKategorier: [
                {
                    navn: '1.1 Oppdragsavtale',
                    veiledere: [
                        {
                            name: '1.1.1 Oppdragsavtale med bilag',
                            id: 'gszbrQ7a2EqWvhsGZ_77Jw',
                            type: 1,
                            relevantForEgetForetak: true,
                            relevantForKunder: true,
                            downloadLink: null,
                        },
                        {
                            name: '1.1.2 Databehandleravtale',
                            id: 'gszbrQ7a2EqWvhsGZ_78Jw',
                            type: 0,
                            relevantForEgetForetak: true,
                            relevantForKunder: true,
                            downloadLink: null,
                        },
                    ],
                },
                {
                    navn: '1.2 Fullmakter',
                    veiledere: [
                        {
                            name: 'Definer regnskapsoppdrag',
                            id: '19RFBNuU7kGUyeVq8Th33A',
                            type: 0,
                            relevantForEgetForetak: true,
                            relevantForKunder: true,
                            downloadLink: null,
                        },
                    ],
                },
            ],
        },
        {
            navn: '2. oppdrag - avtale og fullmakter',
            underKategorier: [
                {
                    navn: '2.1 Oppdragsavtale',
                    veiledere: [
                        {
                            name: '2.1.1 Databehandleravtale',
                            id: 'l9alsiP19U6fLMZA6U5TOw',
                            type: 0,
                            relevantForEgetForetak: true,
                            relevantForKunder: true,
                            downloadLink: null,
                        },
                        {
                            name: '2.1.2 Databehandleravtale',
                            id: 'gszbrQ7a2EqWvhsGZ_87Jw',
                            type: 1,
                            relevantForEgetForetak: true,
                            relevantForKunder: true,
                            downloadLink: null,
                        },
                    ],
                },
            ],
        },
    ],
} as IVeilederliste;

export const veilederMock = (id: string): IVeileder => {
    return mockVeiledere.find((x) => x.id === id) ?? mockVeiledere[0];
};

const mockVeiledere = [
    {
        name: 'Definer regnskapsoppdrag',
        id: '19RFBNuU7kGUyeVq8Th33A',
        type: 1,
        intro: 'Lorem ipsum',
        mainbody:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
        downloadLink: null,
        summaryText: 'summaryText',
    } as IVeileder,
    {
        name: 'Avtalemal - arbeidsavtale for autoriserte regnskapsførere uten oppdragsansvar',
        id: 'l9alsiP19U6fLMZA6U5TOw',
        type: 0,
        intro: 'Lorem ipsum',
        mainbody:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
        downloadLink: '/test/',
        summaryText: 'summaryText',
    } as IVeileder,
    {
        name: 'Arbeidsgiveravgift 2019 -  ikke forhåndsdefinert kontonr eller kontonavn',
        id: 'DctD0uKkUEeTPsLJUBhkDg',
        type: 0,
        intro: 'Lorem ipsum',
        mainbody:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>',
        downloadLink: '/test/',
        summaryText: 'summaryText',
    },
];
export const mockEgetRegnskapsforetakRedigering = {
    text: 'Rediger',
    url: 'https://test.kskomplett.no/',
    target: '_blank',
} as IKundeLinkItem;
export const aktivVeileder = {
    veileder: {
        title: 'Uttalelse om regnskapsoppdrag - avgi uttalelse',
        version: 1,
        schemaVersion: 3,
        completed: false,
        path: [503000],
        activeStep: 503000,
        pathSteps: [
            {
                stepNumber: 503000,
                stepHeader: 'Hvem uttalelse skal avgis til',
            },
        ],
        steps: [
            {
                isActive: false,
                stepNumber: 503000,
                visible: false,
                stepHeader: 'Hvem uttalelse skal avgis til',
                description: 'Opplysninger om ny regnskapsfører',
                extraDescription: '',
                stepProcessed: false,
                questions: [
                    {
                        questionNumber: 503000,
                        questionText: 'Regnskapsforetakets navn',
                        extraQuestionDescription: '',
                        questionTag: 'KSNewaccountant',
                        questionOptional: false,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 14,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503010,
                        questionText: 'Kontaktperson (frivillig opplysning hvis EPF)',
                        extraQuestionDescription:
                            'Dersom regnskapsforetaket og kontaktpersonen har samme navn (enkeltpersonforetak), kan denne opplysningen sløyfes.',
                        questionTag: 'KSNewaccountantcontactperson',
                        questionOptional: true,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 14,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503020,
                        questionText: 'E-post eller postadresse',
                        extraQuestionDescription:
                            'Hvis uttalelsen skal sendes per mail kan du legge inn e-postadressen her, og droppe ordinær postadresse.',
                        questionTag: 'KSNewaccountantadresse',
                        questionOptional: false,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 14,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503030,
                        questionText: 'Postnummer (frivillig opplysning)',
                        extraQuestionDescription: 'Skal uttalelsen sendes per post legger du inn postnummer her',
                        questionTag: 'KSNewaccountantpostnumber',
                        questionOptional: true,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 16,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503040,
                        questionText: 'Poststed (frivillig opplysning)',
                        extraQuestionDescription: 'Skal uttalelsen sendes per post legger du inn poststed',
                        questionTag: 'KSNewaccountantpostarea',
                        questionOptional: true,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 16,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503050,
                        questionText: 'Når er anmodningen om uttalelse datert?',
                        extraQuestionDescription: '',
                        questionTag: 'KSDateofRequest',
                        questionOptional: false,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 3,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                ],
            },
            {
                isActive: false,
                stepNumber: 503100,
                visible: false,
                stepHeader: 'Type uttalelse',
                description:
                    '<strong>Informasjon:<br></strong>Kravet til hva det skal avgis uttalelse om er nærmere regulert i GRFS 2.2. Her er det lagt til grunn at tidligere regnskapsbyrå som et minimum skal opplyse om forhold knyttet til oppdragsutførelsen fra siste år som innebærer mislighold av oppdragsavtalen.<br><br>GRFS 2.2 sier videre at forhold som påpekes fortrinnsvis bør være tatt opp skriftlig med oppdragsgiver. Etter vår oppfatning innebærer dette at dersom forholdet ikke er tatt opp skriftlig med kunden, bør regnskapsfører være forsiktig med å ta opp forholdet med ny regnskapsfører.',
                extraDescription: '',
                stepProcessed: false,
                questions: [
                    {
                        questionNumber: 503100,
                        questionText: 'Hva slags uttalelse skal avgis?',
                        extraQuestionDescription:
                            'Når du velger de forskjellige type uttalelsene, vil denne standardteksten utfylles i brevet som generes i veilederen. Hvis du ønsker en annen tekst, kan du velge andre forhold og legge inn den teksten du selv ønsker. Brevet blir tilgjengelig i Word-format, slik at du kan endre teksten hvis du ønsker dette.',
                        questionTag: '',
                        questionOptional: false,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 7,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [
                            'Ren uttalelse, uten kritikkverdige forhold',
                            'Bilag mv har gjentatte ganger blitt levert senere enn avtalte leveringsfrister.',
                            'Sentral dokumentasjon, som kunden burde ha forstått er viktig for oss, er holdt tilbake.',
                            'Dokumentasjon av omsetning tilfredsstiller ikke bokføringsregelverket, til tross for at vi har påpekt forholdet flere ganger.',
                            'Vi mener å ha blitt forsøkt pålagt å utføre oppdraget i strid med regelverk.',
                            'Våre honorarkrav har ikke blitt betalt innen forfall, og pr i dag er vårt tilgodehavende hos kunden kr',
                            'Andre forhold spesifisert under',
                        ],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503110,
                        questionText: '',
                        extraQuestionDescription: '',
                        questionTag: 'KSUttalelseAndreForhold',
                        questionOptional: true,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 10,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: true,
                            containsCalculations: false,
                            columns: [
                                {
                                    number: 1,
                                    type: 2,
                                    description: '',
                                    header: 'Andre forhold',
                                    alternativesValue: [],
                                },
                            ],
                            rows: [],
                        },
                    },
                    {
                        questionNumber: 503120,
                        questionText: 'Hva er forfalt tilgodehavende hos kunden per idag?',
                        extraQuestionDescription: '',
                        questionTag: 'KSUnpaidfee',
                        questionOptional: true,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 18,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                ],
            },
            {
                isActive: true,
                stepNumber: 503200,
                visible: true,
                stepHeader: 'Veilederen er ferdig',
                description:
                    'Har du noen interne notater eller kommentarer som kan være nyttig med videre kontakt kan du skrive det inn her. Dette vil ikke komme i brevet, men i veilederoppsummeringen.',
                extraDescription: '',
                stepProcessed: false,
                questions: [
                    {
                        questionNumber: 503200,
                        questionText: 'Kommentar',
                        extraQuestionDescription: '',
                        questionTag: '',
                        questionOptional: false,
                        questionHidden: false,
                        answerHidden: false,
                        answerType: 2,
                        stringValue: '',
                        numberValue: 0,
                        doubleValue: 0,
                        dateValue: '0001-01-01T00:00:00Z',
                        alternativeValues: [],
                        selectedValues: [],
                        tabularValue: {
                            canAddRows: false,
                            containsCalculations: false,
                            columns: [],
                            rows: [],
                        },
                    },
                ],
            },
        ],
        includeFlags: [
            {
                key: 'KSIngenkritikk',
                include: false,
            },
            {
                key: 'KSkritikk',
                include: false,
            },
            {
                key: 'KSSenBilagslevering',
                include: false,
            },
            {
                key: 'KSIkkemottattdok',
                include: false,
            },
            {
                key: 'KSIkkeriktigdok',
                include: false,
            },
            {
                key: 'KSStridmedRegelverk',
                include: false,
            },
            {
                key: 'KSManglendebetaling',
                include: false,
            },
            {
                key: 'KSEgenAnnenKommentar',
                include: false,
            },
            {
                key: 'KSRenOgKommentar',
                include: false,
            },
            {
                key: 'KSTest',
                include: false,
            },
        ] as IFlags,
    },
} as IActiveVeilederApiResponse;

export const steg = {
    isActive: true,
    stepNumber: 503100,
    visible: true,
    stepHeader: 'Type uttalelse',
    description:
        '<strong>Informasjon:<br></strong>Kravet til hva det skal avgis uttalelse om er nærmere regulert i GRFS 2.2. Her er det lagt til grunn at tidligere regnskapsbyrå som et minimum skal opplyse om forhold knyttet til oppdragsutførelsen fra siste år som innebærer mislighold av oppdragsavtalen.<br><br>GRFS 2.2 sier videre at forhold som påpekes fortrinnsvis bør være tatt opp skriftlig med oppdragsgiver. Etter vår oppfatning innebærer dette at dersom forholdet ikke er tatt opp skriftlig med kunden, bør regnskapsfører være forsiktig med å ta opp forholdet med ny regnskapsfører.',
    extraDescription: '',
    stepProcessed: false,
    questions: [
        {
            questionNumber: 503100,
            questionText: 'Hva slags uttalelse skal avgis?',
            extraQuestionDescription:
                'Når du velger de forskjellige type uttalelsene, vil denne standardteksten utfylles i brevet som generes i veilederen. Hvis du ønsker en annen tekst, kan du velge andre forhold og legge inn den teksten du selv ønsker. Brevet blir tilgjengelig i Word-format, slik at du kan endre teksten hvis du ønsker dette.',
            questionTag: '',
            questionOptional: false,
            questionHidden: false,
            answerHidden: false,
            answerType: 7,
            stringValue: '',
            numberValue: 0,
            doubleValue: 0,
            dateValue: '0001-01-01T00:00:00Z',
            alternativeValues: [
                'Ren uttalelse, uten kritikkverdige forhold',
                'Bilag mv har gjentatte ganger blitt levert senere enn avtalte leveringsfrister.',
                'Sentral dokumentasjon, som kunden burde ha forstått er viktig for oss, er holdt tilbake.',
                'Dokumentasjon av omsetning tilfredsstiller ikke bokføringsregelverket, til tross for at vi har påpekt forholdet flere ganger.',
                'Vi mener å ha blitt forsøkt pålagt å utføre oppdraget i strid med regelverk.',
                'Våre honorarkrav har ikke blitt betalt innen forfall, og pr i dag er vårt tilgodehavende hos kunden kr',
                'Andre forhold spesifisert under',
            ],
            selectedValues: [],
            tabularValue: {
                canAddRows: false,
                containsCalculations: false,
                columns: [],
                rows: [],
            },
        },
        {
            questionNumber: 503110,
            questionText: '',
            extraQuestionDescription: '',
            questionTag: 'KSUttalelseAndreForhold',
            questionOptional: true,
            questionHidden: false,
            answerHidden: false,
            answerType: 10,
            stringValue: '',
            numberValue: 0,
            doubleValue: 0,
            dateValue: '0001-01-01T00:00:00Z',
            alternativeValues: [],
            selectedValues: [],
            tabularValue: {
                canAddRows: true,
                containsCalculations: false,
                columns: [
                    {
                        number: 1,
                        type: 2,
                        description: '',
                        header: 'Andre forhold',
                        alternativesValue: [],
                    },
                ],
                rows: [],
            },
        },
        {
            questionNumber: 503120,
            questionText: 'Hva er forfalt tilgodehavende hos kunden per idag?',
            extraQuestionDescription: '',
            questionTag: 'KSUnpaidfee',
            questionOptional: true,
            questionHidden: false,
            answerHidden: false,
            answerType: 18,
            stringValue: '',
            numberValue: 0,
            doubleValue: 0,
            dateValue: '0001-01-01T00:00:00Z',
            alternativeValues: [],
            selectedValues: [],
            tabularValue: {
                canAddRows: false,
                containsCalculations: false,
                columns: [],
                rows: [],
            },
        },
    ],
} as IVeilederStep;

export const veilederStepMock = {
    stepNumber: 1,
    stepMessage:
        'Steg Beskjed: Regnskapsfører trenger særskilt fullmakt for å kunne utføre oppgaver for kunden i Altinn. Dette gjelder selv om du som regnskapsfører har fått automatisk tilgang til Altinn, via registrering som regnskapsfører for kunden i Enhetsregisteret. Det er fullmakten fra kunden som gir deg rett til å samhandle med det offentlige elektronisk.',
    description: 'Beskrivelse: Ønsker du å ha en fullmakt som lyder på «oppdragsansvarlig» eller på nærmere angitte personer?',
    extraDescription: '',
    questions: [
        {
            answerType: 10,
            questionNumber: 666,
            questionText: 'VIP medlemmer',
            extraQuestionDescription: 'Ekstra spørsmål beskrivelse nr 2',
            tabularValue: {
                canAddRows: true,
                containsCalculations: false,
                columns: [
                    {
                        number: 1,
                        type: 14,
                        header: 'Fornavn',
                        alternativesValue: [],
                    },
                    {
                        number: 1,
                        type: 14,
                        header: 'Etternavn',
                        alternativesValue: [],
                    },
                    {
                        number: 1,
                        type: 3,
                        header: 'Fødselsdato',
                        alternativesValue: [],
                    },
                    {
                        number: 1,
                        type: 14,
                        header: 'Adresse',
                        alternativesValue: [],
                    },
                    {
                        number: 2,
                        type: 17,
                        header: 'Postnummer',
                        alternativesValue: [],
                    },
                    {
                        number: 2,
                        type: 14,
                        header: 'Sted',
                        alternativesValue: [],
                    },
                    {
                        number: 2,
                        type: 2,
                        header: 'Kommentar',
                        alternativesValue: [],
                    },
                ],
                rows: [
                    {
                        cells: [
                            { stringValue: 'Johan' },
                            { stringValue: 'Stene' },
                            { cellDateValue: new Date('05 Feb 1986') },
                            { stringValue: 'Turbinveien 42' },
                            { stringValue: '0195' },
                            { stringValue: 'Oslo' },
                            {
                                stringValue: 'Cras id dui. Vivamus laoreet. Vestibulum eu odio.',
                            },
                        ],
                    },
                    {
                        cells: [
                            {
                                stringValue: 'Pippilotta Viktualia Rullgardina Chrysmynta',
                            },
                            { stringValue: 'Efraimsdatter Langstrømpe' },
                            { cellDateValue: new Date('21 May 1945') },
                            { stringValue: 'Villa Villekulla' },
                            { stringValue: '1111' },
                            {
                                stringValue: 'Taumatawhakatangihangak oauauotamateaturipukaka pikimaungahoronukupokaiwhe nua kitanatahu',
                            },
                            {
                                stringValue:
                                    'Hovedpersonen i en serie barnebøker av Astrid Lindgren; de tre grunnleggende bøkene utkom fra 1945 til 1948. Pippi er Lindgrens mest kjente litterære figur, og har også opptrådt i flere filmer. Olle Hellboms filmer og tv-serie fra 1969–1970 er den mest omfattende filmversjonen.',
                            },
                        ],
                    },
                ],
            },
            alternativeValues: null,
        },
        {
            answerType: 10,
            questionNumber: 1,
            questionText: 'Spørsmåls Tekst: Navn',
            extraQuestionDescription: 'Ekstra Spørsmåls Beskrivelse nr 1',
            tabularValue: {
                canAddRows: true,
                containsCalculations: false,
                columns: [
                    {
                        number: 1,
                        type: 14,
                        header: 'Beskrivelse for kolonne 1 spørsmål 1: Langtekst',
                        alternativesValue: null,
                    },
                ],
                rows: [],
            },
            alternativeValues: null,
        },
        {
            answerType: 10,
            questionNumber: 2,
            questionText: 'Spørsmåls Tekst: Dato og sted for signering*',
            extraQuestionDescription: 'Ekstra spørsmål beskrivelse nr 2',
            tabularValue: {
                canAddRows: false,
                containsCalculations: false,
                columns: [
                    {
                        number: 1,
                        type: 3,
                        header: 'Beskrivelse for kolonne nr 1 spørsmål 2: Dato',
                        alternativesValue: [],
                    },
                    {
                        number: 2,
                        type: 12,
                        header: 'Beskrivelse for kolonne nr 2 spørsmål 2: Sted',
                        alternativesValue: [],
                    },
                ],
                rows: [],
            },
            alternativeValues: null,
        },
        {
            answerType: 10,
            questionNumber: 3,
            questionText: 'Spørsmåls tekst nr 3: Navn og tittel til den hos kunden som signerer fullmakten*',
            extraQuestionDescription: null,
            tabularValue: {
                canAddRows: false,
                containsCalculations: false,
                columns: [
                    {
                        number: 1,
                        type: 12,
                        header: 'Tredje spørsmåls beskrivelse kolonne nr 1: Navn',
                        alternativesValue: [],
                    },
                    {
                        number: 2,
                        type: 12,
                        header: 'Tredje spørsmåls beskrivelse kolonne nr 2: Tittel',
                        alternativesValue: [],
                    },
                ],
                rows: [],
            },
            alternativeValues: null,
        },
        {
            answerType: 9,
            questionNumber: 4,
            questionText: 'Fjerde spørsmål: Ønsker du fullmakten i norsk eller engelsk utgave?*',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: ['Norsk', 'English', 'Begge Deler'],
        },
        {
            answerType: 1,
            questionNumber: 10,
            questionText: 'Select one < Alternatives>',
            extraQuestionDescription:
                '<h3>ExtraQuestionDescription</h3><p>Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Praesent turpis. Praesent nonummy mi in odio. Pellentesque commodo eros a enim. Nullam tincidunt adipiscing enim.</p>',
            tabularValue: null,
            alternativeValues: ['Alternative 1', 'Alternative 2', 'Alternative 3', 'Alternative 4'],
        },
        {
            answerType: 2,
            questionNumber: 20,
            questionText: 'Comment something < Comment>',
            extraQuestionDescription: 'ExtraQuestionDescription',
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 3,
            questionNumber: 30,
            questionText: 'Pick a day < Date>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 4,
            questionNumber: 40,
            questionText: 'Type a double < Double>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 5,
            questionNumber: 50,
            questionText: 'Type a decimal < Decimal>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 6,
            questionNumber: 60,
            questionText: 'Select one < DropDown>',
            extraQuestionDescription: 'ExtraQuestionDescription 5',
            tabularValue: null,
            alternativeValues: ['Alternative 1', 'Alternative 2', 'Alternative 3', 'Alternative 4'],
        },
        {
            answerType: 7,
            questionNumber: 70,
            questionText: 'Choose many < MultiSelect>',
            extraQuestionDescription: 'ExtraQuestionDescription',
            tabularValue: null,
            alternativeValues: ['Alternative 1', 'Alternative 2', 'Alternative 3', 'Alternative 4'],
        },
        {
            answerType: 8,
            questionNumber: 80,
            questionText: 'Type a number < Number>',
            extraQuestionDescription: 'ExtraQuestionDescription',
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 9,
            questionNumber: 90,
            questionText: 'Choose one < RadioButton>',
            extraQuestionDescription: 'ExtraQuestionDescription',
            tabularValue: null,
            alternativeValues: ['Alternative 1', 'Alternative 2', 'Alternative 3', 'Alternative 4'],
        },
        {
            answerType: 11,
            questionNumber: 100,
            questionText: 'Type a string < String>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 12,
            questionNumber: 110,
            questionText: 'Type a short text < ShortText>',
            extraQuestionDescription:
                '<h3>Help text</h3> Pellentesque ut neque. Donec interdum, metus et hendrerit aliquet, dolor diam sagittis ligula, eget egestas libero turpis vel mi. Vivamus quis mi.',
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 13,
            questionNumber: 120,
            questionText: 'Type a medium text < MediumText>',
            extraQuestionDescription: 'ExtraQuestionDescription',
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 14,
            questionNumber: 130,
            questionText: 'Type a long text < LongText>',
            extraQuestionDescription: 'ExtraQuestionDescription',
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 15,
            questionNumber: 140,
            questionText: 'Type a string < Text>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 16,
            questionNumber: 150,
            questionText: 'Type a mini text < MiniText>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 17,
            questionNumber: 160,
            questionText: 'Type a micro text < MicroText>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 18,
            questionNumber: 170,
            questionText: 'Type a small number < SmallNumber>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
        {
            answerType: 19,
            questionNumber: 180,
            questionText: 'Type a small decimal < SmallDecimal>',
            extraQuestionDescription: null,
            tabularValue: null,
            alternativeValues: null,
        },
    ],
} as IVeilederStep;
