import axios from 'axios';
import mockApi from './api-mock';
import { isPrototype } from '../../../../shared/lib/utils';
import {
    IActiveVeilederApiResponse,
    IBruker,
    IDokumentStatistikk,
    IVeilederliste,
    IKundeFull,
    IKundeLinkItem,
    IKunde,
    IRadgiver,
    IVeileder,
    DokumentOversikt,
    IVeilederIArbeid,
    IVeilederStep,
    Dokument,
    IEpost,
} from './types';

export interface IApi {
    getVeiledere(): Promise<IVeilederliste>;
    getVeileder(veilederId: string, kundeId: string): Promise<IVeileder>;
    getActiveVeileder(veilederId: string, kundeId: string): Promise<IActiveVeilederApiResponse>;
    getVeiledereIArbeid(kundeId: string): Promise<Array<IVeilederIArbeid>>;
    deleteVeilederIArbeid(kundeId: string, veilederId: string): Promise<boolean>;
    getRadgivere(): Promise<Array<IRadgiver>>;
    getKunde(kundeId: string): Promise<IKundeFull>;
    postKunde(kunde: IKundeFull): Promise<string>;
    getKundeByOrgnr(orgnr: string): Promise<IKundeFull>;
    deleteKunde(kundeId: string): Promise<boolean>;
    getKunder(): Promise<Array<IKunde>>;
    importKunder(orgnr: string): Promise<Array<IKunde>>;
    getInnloggetBruker(): Promise<IBruker>;
    getDigitalSigneringForetak(): Promise<boolean>;
    getDigitalSigneringEksterntForetak(): Promise<boolean>;
    postKundeAnsvarlig(radgiverId: string, kundeIder: Array<string>): Promise<string>;
    getDokumenterForKunde(kundeId: string): Promise<Array<DokumentOversikt>>;
    getDokumenterForEgetRegnskapsforetak(): Promise<Array<DokumentOversikt>>;
    getDokumentStatistikkForKunde(kundeId: string): Promise<IDokumentStatistikk>;
    deleteKundeDokument(kundeId: string, dokumentId: string): Promise<boolean>;
    deleteDokumentForEgetRegnskapsforetak(dokumentId: string): Promise<boolean>;
    deleteDokumentMappeForKunde(kundeId: string, dokumentId: string): Promise<boolean>;
    deleteDokumentMappeForEgetRegnskapsforetak(dokumentId: string): Promise<boolean>;
    deletePdfDokumenter(dokumentMappeId: string, pdfId: string, kundeId: string): Promise<boolean>;
    deleteDokumenter(dokumentMappeId: string, status: string, kundeId: string): Promise<boolean>;
    getDokumentStatistikkForEgetRegnskapsforetak(): Promise<IDokumentStatistikk>;
    getEgetRegnskapsforetakRedigering(): Promise<IKundeLinkItem>;
    getDokumentForVeileder(kundeId: string, veilederId: string): Promise<Array<Dokument>>;
    sendEpostKunde(kundeId: string, dokumentId: string, eposter: Array<string>): Promise<boolean>;
    sendEpostEgetForetak(dokumentId: string, eposter: Array<string>): Promise<boolean>;
    sendSigneringEpostKunde(kundeId: string, dokumentId: string, epostSigneringForm: IEpost): Promise<boolean>;
    sendSigneringEpostEgetForetak(dokumentId: string, epostSigneringForm: IEpost): Promise<boolean>;
    nesteStegPost(kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse>;
    forrigeStegPost(kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse>;
    lagreStegPost(kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse>;
    gjenopptaFraStegPost(kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse>;
    ferdigstillPost(kundeId: string, veilederid: string): Promise<boolean>;
    tilbakestillStegPost(kundeId: string, veilederId: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse>;
}

const apiPaths = window.apiPaths;

const get = (apiPath: string | undefined, params = {}) => {
    return new Promise((resolve, reject) => {
        return apiPath
            ? axios
                  .get(apiPath, { params })
                  .then((res) => resolve(res.data))
                  .catch((err) => reject(err.response))
            : reject('Error: no api path set');
    });
};
const post = (apiPath: string | undefined, params = {}) => {
    return new Promise((resolve, reject) => {
        return apiPath
            ? axios
                  .post(apiPath, params)
                  .then((res) => resolve(res.data))
                  .catch((err) => reject(err.response))
            : reject('Error: no api path set');
    });
};
const del = (apiPath: string) => {
    return new Promise((resolve, reject) => {
        return apiPath
            ? axios
                  .delete(apiPath)
                  .then((res) => resolve(res.data))
                  .catch((err) => reject(err.response))
            : reject('Error: no api path set');
    });
};

const getVeiledere = (): Promise<IVeilederliste> => {
    return get(apiPaths.veilederListe) as Promise<IVeilederliste>;
};

export const getVeileder = (veilederId: string, kundeId: string = null): Promise<IVeileder> => {
    const url = `${apiPaths.veileder}/${veilederId}/${kundeId ? kundeId : ''}`;
    return get(url) as Promise<IVeileder>;
};

export const getActiveVeileder = (veilederId: string, kundeId: string): Promise<IActiveVeilederApiResponse> => {
    const url = `${apiPaths.veileder}/aktiv/${veilederId}/${kundeId}`;
    return get(url) as Promise<IActiveVeilederApiResponse>;
};

export const getVeiledereIArbeid = (kundeId: string): Promise<Array<IVeilederIArbeid>> => {
    const url = `${apiPaths.veileder}/aktive/${kundeId}`;

    return get(url) as Promise<Array<IVeilederIArbeid>>;
};

export const deleteVeilederIArbeid = (kundeId: string, veilederId: string) => {
    const url = `${apiPaths.veileder}/aktiv/${veilederId}/${kundeId}`;

    return del(url);
};

export const getRadgivere = (): Promise<Array<IRadgiver>> => {
    return get(apiPaths.radgivere) as Promise<Array<IRadgiver>>;
};

export const getKunde = (kundeId: string): Promise<IKundeFull> => {
    return get(`${apiPaths.kunder}/${kundeId}`) as Promise<IKundeFull>;
};

export const postKunde = (kunde: IKundeFull): Promise<string> => {
    return post(`${apiPaths.kunder}/`, kunde) as Promise<string>;
};

export const getKundeByOrgnr = (orgnr: string): Promise<IKundeFull> => {
    return post(`${apiPaths.kunder}/${orgnr}`) as Promise<IKundeFull>;
};

export const deleteKunde = (kundeId: string) => {
    return del(`${apiPaths.kunder}/${kundeId}`);
};

export const getKunder = (): Promise<Array<IKunde>> => {
    return get(apiPaths.kunder) as Promise<Array<IKunde>>;
};

export const importKunder = (orgnr: string): Promise<Array<IKunde>> => {
    return post(`${apiPaths.kundeimport}/${orgnr}`) as Promise<Array<IKunde>>;
};

export const getInnloggetBruker = (): Promise<IBruker> => {
    return get(apiPaths.innloggetbruker) as Promise<IBruker>;
};

export const getDigitalSigneringForetak = (): Promise<boolean> => {
    return get(apiPaths.digitalsignering) as Promise<boolean>;
};

export const getDigitalSigneringEksterntForetak = (): Promise<boolean> => {
    return get(apiPaths.digitalsigneringEksternt) as Promise<boolean>;
};

export const postKundeAnsvarlig = (radgiverId: string, kundeIder: Array<string>): Promise<string> => {
    return post(`${apiPaths.kunder}/kundeansvarlig/${radgiverId}`, kundeIder) as Promise<string>;
};

export const getDokumenterForKunde = (kundeId: string): Promise<Array<DokumentOversikt>> => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt`;
    return get(url) as Promise<Array<DokumentOversikt>>;
};

export const getDokumenterForEgetRegnskapsforetak = (): Promise<Array<DokumentOversikt>> => {
    const url = `${apiPaths.dokumentoversiktEgetRegnskapsforetak}`;
    return get(url) as Promise<Array<DokumentOversikt>>;
};

export const getDokumentStatistikkForKunde = (kundeId: string): Promise<IDokumentStatistikk> => {
    return get(`${apiPaths.kunder}/${kundeId}/dokumentoversikt/statistikk`) as Promise<IDokumentStatistikk>;
};

export const getDokumentStatistikkForEgetRegnskapsforetak = (): Promise<IDokumentStatistikk> => {
    return get(`${apiPaths.dokumentoversiktEgetRegnskapsforetak}/statistikk`) as Promise<IDokumentStatistikk>;
};

export const getEgetRegnskapsforetakRedigering = (): Promise<IKundeLinkItem> => {
    return get(`${apiPaths.egetRegnskapsforetakRedigering}`) as Promise<IKundeLinkItem>;
};

export const deleteKundeDokument = (kundeId: string, dokumentId: string) => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt/dokumenter/${dokumentId}`;
    return del(url);
};

export const deletePdfDokumenter = (dokumentMappeId: string, pdfId: string, kundeId: string) => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt/dokumenter/pdf/${dokumentMappeId}/${pdfId}`;

    return del(url);
};

export const deleteDokumenter = (dokumentMappeId: string, status: string, kundeId: string) => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt/dokumenter/word/${dokumentMappeId}/${status}`;

    return del(url);
};

export const deleteDokumentForEgetRegnskapsforetak = (dokumentId: string) => {
    const url = `${apiPaths.dokumentoversiktEgetRegnskapsforetak}/dokumenter/${dokumentId}`;
    return del(url);
};

export const deleteDokumentMappeForKunde = (kundeId: string, dokumentId: string) => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt/${dokumentId}`;
    return del(url);
};

export const deleteDokumentMappeForEgetRegnskapsforetak = (dokumentMappeId: string) => {
    const url = `${apiPaths.dokumentoversiktEgetRegnskapsforetak}/${dokumentMappeId}`;
    return del(url);
};

export const getDokumentForVeileder = (kundeId: string, veilederId: string): Promise<Array<Dokument>> => {
    const url = `${apiPaths.veileder}/${veilederId}/${kundeId}/dokumenter`;
    return get(url) as Promise<Array<Dokument>>;
};

export const sendEpostKunde = (kundeId: string, dokumentId: string, eposter: Array<string>): Promise<boolean> => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt/dokumenter/${dokumentId}/sendepost`;
    return post(url, eposter) as Promise<boolean>;
};

export const sendEpostEgetForetak = (dokumentId: string, eposter: Array<string>): Promise<boolean> => {
    const url = `${apiPaths.dokumentoversiktEgetRegnskapsforetakDokumenter}/${dokumentId}/sendepost`;
    return post(url, eposter) as Promise<boolean>;
};

export const sendSigneringEpostKunde = (kundeId: string, dokumentId: string, epostSigneringForm: IEpost): Promise<boolean> => {
    const url = `${apiPaths.kunder}/${kundeId}/dokumentoversikt/dokumenter/${dokumentId}/sendepostsignering`;
    return post(url, epostSigneringForm) as Promise<boolean>;
};

export const sendSigneringEpostEgetForetak = (dokumentId: string, epostSigneringForm: IEpost): Promise<boolean> => {
    const url = `${apiPaths.dokumentoversiktEgetRegnskapsforetakDokumenter}/${dokumentId}/sendepostsignering`;
    return post(url, epostSigneringForm) as Promise<boolean>;
};

export const forrigeStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    return post(`${apiPaths.veileder}/aktiv/forrigesteg/${veilederid}/${kundeId}`, steg) as Promise<IActiveVeilederApiResponse>;
};

export const nesteStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    return post(`${apiPaths.veileder}/aktiv/nestesteg/${veilederid}/${kundeId}`, steg) as Promise<IActiveVeilederApiResponse>;
};

export const lagreStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    return post(`${apiPaths.veileder}/aktiv/lagresteg/${veilederid}/${kundeId}`, steg) as Promise<IActiveVeilederApiResponse>;
};

export const gjenopptaFraStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    return post(`${apiPaths.veileder}/aktiv/gjenopptafrasteg/${veilederid}/${kundeId}`, steg) as Promise<IActiveVeilederApiResponse>;
};

export const ferdigstillPost = (kundeId: string, veilederid: string): Promise<boolean> => {
    return post(`${apiPaths.veileder}/aktiv/ferdigstill/${veilederid}/${kundeId}`) as Promise<boolean>;
};

export const tilbakestillStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    return post(`${apiPaths.veileder}/aktiv/tilbakestillsteg/${veilederid}/${kundeId}`, steg) as Promise<IActiveVeilederApiResponse>;
};
const api = {
    getVeiledere,
    getVeileder,
    getActiveVeileder,
    getVeiledereIArbeid,
    deleteVeilederIArbeid,
    getRadgivere,
    getKunde,
    getKundeByOrgnr,
    postKunde,
    deleteKunde,
    getKunder,
    importKunder,
    getInnloggetBruker,
    postKundeAnsvarlig,
    getDokumenterForKunde,
    getDokumenterForEgetRegnskapsforetak,
    getDokumentStatistikkForKunde,
    getDokumentStatistikkForEgetRegnskapsforetak,
    getEgetRegnskapsforetakRedigering,
    deleteKundeDokument,
    deleteDokumentForEgetRegnskapsforetak,
    deleteDokumentMappeForKunde,
    deleteDokumentMappeForEgetRegnskapsforetak,
    getDokumentForVeileder,
    sendEpostKunde,
    sendSigneringEpostKunde,
    sendEpostEgetForetak,
    sendSigneringEpostEgetForetak,
    nesteStegPost,
    forrigeStegPost,
    lagreStegPost,
    gjenopptaFraStegPost,
    ferdigstillPost,
    tilbakestillStegPost,
    getDigitalSigneringForetak,
    getDigitalSigneringEksterntForetak,
    deletePdfDokumenter,
    deleteDokumenter,
} as IApi;

export default !isPrototype() ? api : mockApi;
