import React from 'react';
import { IInputElement } from '../../shared/lib/types';
import CheckboxInput, { ICheckboxInput } from './CheckboxInput';
import FormError from './FormError';
import FormGroupLabelText from './FormGroupLabelText';

export interface ICheckboxGroup extends IInputElement {
    legend: string;
    inputs: Array<ICheckboxInput>;
    value: Array<string>;
    debugLabel?: string;
    answerHidden?: boolean;
    showErrorMessage?: boolean;
    error?: string;
    required?: boolean;
}

const CheckboxGroup = (props: ICheckboxGroup) => {
    function onInputChange(name: string, value: string) {
        const updatedSelectedValues = [...props.value];
        if (props.value.includes(name, 0)) {
            if (!value) {
                updatedSelectedValues.splice(updatedSelectedValues.indexOf(name), 1);
            }
        }
        if (value) {
            updatedSelectedValues.push(name);
        }
        props.onInputChange(props.name, updatedSelectedValues);
    }
    
    const label = `${props.legend}${props.required ? '*' : ''}`;

    return (
        <div className="form-group form-group--checkbox-group">
            <fieldset name={props.legend}>
                <legend className="form-group__label-text">
                    <FormGroupLabelText label={props.debugLabel ? `${label} ${props.debugLabel}`: label} helpText={props.helpText} />
                </legend>
                <ul className="list--unstyled">
                    {props.inputs.length &&
                        props.inputs.map((input, i) => {
                            return (
                                <li key={input.name + '_' + input.value}>
                                    <CheckboxInput 
                                        {...input} 
                                        checked={props.value.includes(input.value)} 
                                        index={i} 
                                        name={input.value.toString()} 
                                        focus={props.focus} onInputChange={onInputChange} />
                                </li>
                            );
                        })}
                </ul>
            </fieldset>
            {(props.error && props.showErrorMessage) && <FormError error={props.error}/>}
        </div>
    );
};

export default CheckboxGroup;
