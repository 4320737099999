import React, { useEffect, useRef, useState, FormEvent } from 'react';
import { IInputElement } from '../../shared/lib/types';
import { inputId } from '../../shared/lib/utils';

export interface ICheckboxInput extends IInputElement {
    reqired?: boolean;
    checked: boolean;
    index: number;
    answerHidden?: boolean;
}

const CheckboxInput = (props: ICheckboxInput) => {
    const [id] = useState(inputId());
    const input = useRef<HTMLInputElement>(null);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;
        props.onInputChange(props.name, checked);
    };

    useEffect(() => {
        if (props.name === props.focus) {
            input?.current?.focus();
        }
    }, [props.name, props.focus]);

    return (
        <div className="form-group form-group--checkbox">
            <input
                type="checkbox"
                id={id}
                ref={input}
                checked={props.checked}
                onChange={handleChange}
                name={props.name}
                className="form-group__input"
                disabled={props.disabled}
                hidden={props.answerHidden}
            />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: props.label }}></label>
        </div>
    );
};

export default CheckboxInput;
