import { Link } from 'react-router-dom';

interface IFeilside {
    readonly errorCode: number;
    readonly url: string;
}
export default function FeilmeldingSide(props: IFeilside) {
    const UNAUTHORIZED = 401;
    return (
        <div className="error-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h1>{props.errorCode}</h1>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">
                        <span className="error-page__message">{getMessageFromErrorCode(props.errorCode)}</span>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">
                        {props.errorCode === UNAUTHORIZED ? (
                            <Link
                                onClick={() => {
                                    window.location.reload();
                                }}
                                to="#"
                                className="btn btn--primary"
                            >
                                Hjem
                            </Link>
                        ) : (
                            <Link to={props.url} className="btn btn--primary">
                                Hjem
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function getMessageFromErrorCode(errorCode: number) {
    switch (errorCode) {
        case 401:
            return 'Feilkode 401: Du må være innlogget for å vise denne siden.';
        case 403:
            return 'Feilkode 403: Du har ikke rettigheter til å vise denne siden.';
        case 409:
            return 'Feilkode 409: Du har ikke lov til å legge til en duplikat';
        case 500:
            return 'Feilkode 500: Det oppstod en uventet feil.';
        default:
            return 'Ukjent feil. Vennligst prøv igjen.';
    }
}
