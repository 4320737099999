import * as mockdata from './api-mock.data';
import { IApi } from './api';
import {
    Dokument,
    DokumentOversikt,
    IActiveVeilederApiResponse,
    IBruker,
    IDokumentStatistikk,
    IEpost,
    IKunde,
    IKundeFull,
    IKundeLinkItem,
    IRadgiver,
    IVeileder,
    IVeilederIArbeid,
    IVeilederliste,
    IVeilederStep,
} from './types';

const getVeiledere = (): Promise<IVeilederliste> => {
    console.log(`MOCK getVeilederliste()`);
    return Promise.resolve(mockdata.veilederListeMock as IVeilederliste);
};

const getVeileder = (veilederId: string, kundeId: string = null): Promise<IVeileder> => {
    console.log(`MOCK getVeileder(veilederId: ${veilederId}${kundeId ? `, kundeId: ${kundeId}` : ''})`);
    return Promise.resolve(mockdata.veilederMock(veilederId));
};

const getActiveVeileder = (veilederId: string, kundeId: string = null): Promise<IActiveVeilederApiResponse> => {
    console.log(`MOCK getActiveVeileder(veilederId: ${veilederId}, kundeId: ${kundeId})`);
    return Promise.resolve(mockdata.aktivVeileder);
};

const getVeiledereIArbeid = (kundeId: string): Promise<Array<IVeilederIArbeid>> => {
    console.log(`MOCK getVeiledereIArbeid(kundeId: ${kundeId})`);
    return Promise.resolve(mockdata.veiledereIArbeid);
};
const deleteVeilederIArbeid = (kundeId: string, veilederId: string) => {
    console.log(`MOCK deleteVeilederIArbeid(veilederId: ${veilederId}, kundeId: ${kundeId})`);
    return Promise.reject('Can not delete items in mock');
};

const getRadgivere = (): Promise<Array<IRadgiver>> => {
    console.log(`MOCK getRadgivere()`);
    return Promise.resolve(mockdata.radgivereMock as Array<IRadgiver>);
};

const getKunde = (kundeId: string): Promise<IKundeFull> => {
    console.log(`MOCK getKunde(id: ${kundeId})`);
    return Promise.resolve(mockdata.kundeMock as IKundeFull);
};

const getKundeByOrgnr = (orgnr: string): Promise<IKundeFull> => {
    console.log(`MOCK getKundeByOrgnr(orgnr: ${orgnr})`);
    return Promise.resolve(mockdata.kundeMock2) as Promise<IKundeFull>;
};

const postKunde = (kunde: IKundeFull) => {
    console.log(`MOCK postKunde(kunde: ${kunde})`);
    return Promise.reject('Can not post items in mock');
};

const deleteKunde = (kundeId: string) => {
    console.log(`MOCK deleteKunde(kundeId: ${kundeId})`);
    return Promise.reject('Can not delete items in mock');
};

const getKunder = (): Promise<Array<IKunde>> => {
    console.log(`MOCK getKunder()`);
    return Promise.resolve(mockdata.kundelisteMock as Array<IKunde>);
};

const importKunder = (orgnr: string): Promise<Array<IKunde>> => {
    console.log(`MOCK importKunder(orgnr: ${orgnr})`);
    return Promise.resolve([mockdata.kundeMock2, mockdata.kundeMock]) as Promise<Array<IKunde>>;
};

const getInnloggetBruker = (): Promise<IBruker> => {
    console.log(`MOCK getInnloggetBruker()`);
    return Promise.resolve(mockdata.innloggetbrukerMock);
};

const getDigitalSigneringForetak = (): Promise<boolean> => {
    console.log(`MOCK getDigitalSigneringForetak`);

    return Promise.resolve(mockdata.digitalsigneringMock);
};

const getDigitalSigneringEksterntForetak = (): Promise<boolean> => {
    console.log(`MOCK getDigitalSigneringEksterntForetak`);

    return Promise.resolve(mockdata.digitalsigneringMock);
};

const postKundeAnsvarlig = (radgiverId: string, kundeIder: Array<string>): Promise<string> => {
    console.log(`MOCK postKundeAnsvarlig(radgiverId: ${radgiverId}, kundeIder: ${kundeIder})`);
    return Promise.resolve('70000299') as Promise<string>;
};
const getDokumenterForKunde = (kundeId: string): Promise<Array<DokumentOversikt>> => {
    console.log(`MOCK getDokumenterForKunde(kundeid: ${kundeId}`);
    return Promise.resolve(mockdata.dokumenterForKunde) as Promise<Array<DokumentOversikt>>;
};

const getDokumenterForEgetRegnskapsforetak = (): Promise<Array<DokumentOversikt>> => {
    console.log(`MOCK getDokumenterForEgetRegnskapsforetak()`);
    return Promise.resolve(mockdata.dokumenterForKunde) as Promise<Array<DokumentOversikt>>;
};
const getDokumentStatistikkForKunde = (kundeId: string): Promise<IDokumentStatistikk> => {
    console.log(`MOCK getDokumentStatistikForKunde(kundeid: ${kundeId})`);
    return Promise.resolve(mockdata.dokumentstatistikkMock);
};

const getDokumentStatistikkForEgetRegnskapsforetak = (): Promise<IDokumentStatistikk> => {
    console.log(`MOCK getDokumentStatistikForEgetRegnskapsforetak()`);
    return Promise.resolve(mockdata.dokumentstatistikkMock);
};

const getEgetRegnskapsforetakRedigering = (): Promise<IKundeLinkItem> => {
    return Promise.resolve(mockdata.mockEgetRegnskapsforetakRedigering) as Promise<IKundeLinkItem>;
};

const deleteKundeDokument = (kundeId: string, dokumentId: string) => {
    console.log(`MOCK deleteKundeDokument(dokumentId: ${dokumentId}, kundeId: ${kundeId})`);
    return Promise.reject('Can not delete items in mock');
};

const deleteDokumenter = (dokumentMappeId: string, status: string, kundeId: string) => {
    console.log(`MOCK deleteDokumenter(dokumentMappeId: ${dokumentMappeId}, status: ${status}, kundeId: ${kundeId})`);
    return Promise.reject('Can not delete items in mock');
};

const deletePdfDokumenter = (dokumentMappeId: string, pdfId: string, kundeId: string) => {
    console.log(`MOCK deleteDokumenter(dokumentMappeId: ${dokumentMappeId}, pdfId: ${pdfId}, kundeId: ${kundeId})`);
    return Promise.reject('Can not delete items in mock');
};

const deleteDokumentForEgetRegnskapsforetak = (dokumentId: string) => {
    console.log(`MOCK deleteDokumentForEgetRegnskapsforetak(dokumentId: ${dokumentId})`);
    return Promise.reject('Can not delete items in mock');
};

const deleteDokumentMappeForKunde = (kundeId: string, dokumentId: string) => {
    console.log(`MOCK deleteDokumentMappeForKunde(kundeId: ${kundeId},dokumentMappeId: ${dokumentId})`);
    return Promise.reject('Can not delete items in mock');
};

const deleteDokumentMappeForEgetRegnskapsforetak = (dokumentId: string) => {
    console.log(`MOCK deleteDokumentMappeForEgetRegnskapsforetak(dokumentMappeId: ${dokumentId})`);
    return Promise.reject('Can not delete items in mock');
};

const getDokumentForVeileder = (kundeId: string, veilederId: string): Promise<Array<Dokument>> => {
    console.log(`MOCK getDokumentForVeileder(kundeid: ${kundeId}, veileder: ${veilederId})`);
    return Promise.resolve(mockdata.dokumenterForVeileder) as Promise<Array<Dokument>>;
};

const sendEpostKunde = (kundeId: string, dokumentId: string, eposter: Array<string>): Promise<boolean> => {
    console.log(`MOCK sendEpostKunde(kundeId: ${kundeId}, dokumentId: ${dokumentId}, eposter: ${eposter})`);
    return Promise.resolve(true);
};

const sendEpostEgetForetak = (dokumentId: string, eposter: Array<string>): Promise<boolean> => {
    console.log(`MOCK sendEpostEgetForetak( dokumentId: ${dokumentId}, eposter: ${eposter})`);
    return Promise.resolve(true);
};

const sendSigneringEpostKunde = (kundeId: string, dokumentId: string, epostSigneringForm: IEpost): Promise<boolean> => {
    console.log(`MOCK sendSigneringEpostKunde(kundeId: ${kundeId}, dokumentId: ${dokumentId}, eposter: ${epostSigneringForm})`);
    return Promise.resolve(true);
};

const sendSigneringEpostEgetForetak = (dokumentId: string, epostSigneringForm: IEpost): Promise<boolean> => {
    console.log(`MOCK sendSigneringEpostEgetForetak( dokumentId: ${dokumentId}, eposter: ${epostSigneringForm})`);
    return Promise.resolve(true);
};

const nesteStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    console.log(`MOCK nesteStegPost(kundeid: ${kundeId}, veilederid: ${veilederid})`);
    return Promise.resolve(mockdata.aktivVeileder) as Promise<IActiveVeilederApiResponse>;
};

const forrigeStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    console.log(`MOCK forrigeStegPost(kundeid: ${kundeId}, veilederid: ${veilederid})`);
    return Promise.resolve(mockdata.aktivVeileder) as Promise<IActiveVeilederApiResponse>;
};

const lagreStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    console.log(`MOCK lagreStegPost(kundeid: ${kundeId}, veilederid: ${veilederid})`);
    return Promise.resolve(mockdata.aktivVeileder) as Promise<IActiveVeilederApiResponse>;
};

const gjenopptaFraStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    console.log(`MOCK gjenopptaFraStegPost(kundeid: ${kundeId}, veilederid: ${veilederid})`);
    return Promise.resolve(mockdata.aktivVeileder) as Promise<IActiveVeilederApiResponse>;
};

const ferdigstillPost = (kundeId: string, veilederid: string): Promise<boolean> => {
    console.log(`MOCK ferdigstillPost(kundeid: ${kundeId}, veilederid: ${veilederid})`);
    return Promise.resolve(true);
};

const tilbakestillStegPost = (kundeId: string, veilederid: string, steg: IVeilederStep): Promise<IActiveVeilederApiResponse> => {
    console.log(`MOCK tilbakestillStegPost(kundeid: ${kundeId}, veilederid: ${veilederid})`);
    return Promise.resolve(mockdata.aktivVeileder) as Promise<IActiveVeilederApiResponse>;
};

const apiMock = {
    getVeiledere,
    getVeileder,
    getActiveVeileder,
    getVeiledereIArbeid,
    deleteVeilederIArbeid,
    getRadgivere,
    getKunde,
    getKundeByOrgnr,
    postKunde,
    deleteKunde,
    getKunder,
    importKunder,
    getInnloggetBruker,
    getDigitalSigneringForetak,
    getDigitalSigneringEksterntForetak,
    postKundeAnsvarlig,
    getDokumenterForKunde,
    getDokumenterForEgetRegnskapsforetak,
    getDokumentStatistikkForKunde,
    getDokumentStatistikkForEgetRegnskapsforetak,
    getEgetRegnskapsforetakRedigering,
    deleteKundeDokument,
    deleteDokumenter,
    deletePdfDokumenter,
    deleteDokumentForEgetRegnskapsforetak,
    deleteDokumentMappeForKunde,
    deleteDokumentMappeForEgetRegnskapsforetak,
    getDokumentForVeileder,
    sendEpostKunde,
    sendSigneringEpostKunde,
    sendEpostEgetForetak,
    sendSigneringEpostEgetForetak,
    nesteStegPost,
    forrigeStegPost,
    lagreStegPost,
    gjenopptaFraStegPost,
    ferdigstillPost,
    tilbakestillStegPost,
} as IApi;

export default apiMock;
