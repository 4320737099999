interface IErrorForm{
    error: string;
}
const FormError = ({error}:IErrorForm) => {
    return (
        <div className="alert alert-error">
            <span className="icon icon-varsel_stor" />
            <span className="alert-error--text">{error}</span>
        </div>
    );
};

export default FormError;