import { removeSpaces } from '../../../shared/lib/utils';
import { IKontaktperson, IKunde, IRadgiver, KontaktpersonTyperEnum, KontaktpersonTyperNavn } from '../shared/lib/types';
import { fieldHasMatch, markMatch } from '../shared/lib/filterUtils';

const minQueryLength = 1;

export const filteredKundeliste = (kundeliste: Array<IKunde>, kundeansvarligBrukerId: string, searchQuery: string | undefined = undefined): Array<IKunde> => {
    let kunder = [...kundeliste];

    if (kundeansvarligBrukerId) {
        const egetForetak = kunder.find((item) => item.egetForetak);
        kunder = kunder.filter((kunde) => kunde.kundeansvarlig?.brukerId === kundeansvarligBrukerId);
        
        if(egetForetak)
            kunder.unshift({...egetForetak});
    }
    
    const qArr = searchQuery
        ?.split(' ')
        .filter((q) => q.length > minQueryLength)
        .map((q) => q.toLowerCase().trim());

    if (qArr) {
        kunder = kunder
            .filter((kunde) => {
                return (
                    fieldHasMatch(kunde.navn.toLowerCase(), qArr) ||
                    fieldHasMatch(removeSpaces(kunde.organisasjonsnummer), qArr) ||
                    fieldHasMatch(kunde.kundeansvarlig?.fornavn?.toLowerCase(), qArr) ||
                    fieldHasMatch(kunde.kundeansvarlig?.etternavn?.toLowerCase(), qArr)
                );
            })
            .map((kunde) => {
                return {
                    ...kunde,
                    navn: markMatch(kunde.navn, qArr),
                    organisasjonsnummer: markMatch(removeSpaces(kunde.organisasjonsnummer), qArr),
                    kundeansvarlig: {
                        ...kunde.kundeansvarlig,
                        fornavn: markMatch(kunde.kundeansvarlig?.fornavn, qArr),
                        etternavn: markMatch(kunde.kundeansvarlig?.etternavn, qArr),
                    } as IRadgiver,
                };
            });
    }

    return kunder;
};

export const sortedKundeliste = (kundeliste: Array<IKunde>) => {
    // Sort kunder alphabetically
    kundeliste = kundeliste.sort((a, b) => a.navn.localeCompare(b.navn));

    return kundeliste;
};

export const moveKunderInArray = (kunde: IKunde, kunder: IKunde[]) => {
    const sortedKunder = sortedKundeliste(kunder);
    const filteredArrayOfKunder = sortedKunder.filter((item) => item.id !== kunde.id);
    filteredArrayOfKunder.unshift({ ...kunde });
    
    return filteredArrayOfKunder;
};
export const getKundeansvarlige = (kundeliste: Array<IKunde> = []) => {
    // Find kundeansvarlige, remove duplicates
    return kundeliste.filter((kunde) => kunde.kundeansvarlig && kunde.kundeansvarlig.brukerId).map((kunde) => kunde.kundeansvarlig);
    // .filter((x: any, i: number, a: any) => a.indexOf(x) === i);
};

export const getRadgiverFullName = (radgiver: IRadgiver, indicateInnloggetRadgiver: boolean = true): string => {
    if (!radgiver || !radgiver.fornavn || !radgiver.etternavn) {
        return '';
    }
    return `${radgiver.fornavn} ${radgiver.etternavn}${indicateInnloggetRadgiver && radgiver.innloggetRadgiver ? ' (deg)' : ''}`;
};

export const getKontaktPersonFullName = (person: IKontaktperson, indicateInnloggetKontaktPerson: boolean = true): string => {
    if (!person || !person.fornavn || !person.etternavn) {
        return '';
    }
    return `${person.fornavn} ${person.etternavn}${indicateInnloggetKontaktPerson && person.rolle.type === KontaktpersonTyperEnum.DagligLeder ? ' (' + KontaktpersonTyperNavn[KontaktpersonTyperEnum.DagligLeder] + ')' : ''}`;
};

export const getInnloggetRadgiver = (radgivere: Array<IRadgiver>): IRadgiver => {
    return radgivere.find((x) => x.innloggetRadgiver === true);
};

export const createServerErrorMessage = (errors: object) => {
    let errorMessage = [] as Array<string>;

    Object.entries(errors).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (typeof value[0] === 'object') {
                let nestedError = [] as Array<string>;
                value.forEach((val) => {
                    nestedError.push(...createServerErrorMessage(val));
                });
                if (nestedError?.length) {
                    errorMessage.push(...nestedError);
                }
            } else {
                errorMessage.push(`${key}: ${Array.isArray(value) ? value.join(' ') : value}`);
            }
        }
    });

    return errorMessage;
};

const kundeHelper = {
    filteredKundeliste,
    getKundeansvarlige,
    getRadgiverFullName,
    createServerErrorMessage
};

export default kundeHelper;
