import React from 'react';
import { validateInput } from '../../../shared/lib/validators';
import SelectInput, { ISelectInputOption } from '../../form/SelectInput';
import TextInput from '../../form/TextInput';
import { IKontaktpersonForm, updateInput } from '../kunder/kundedetaljer/kundeFormHelper';
import { KontaktpersonTyperEnum, KontaktpersonTyperNavn } from '../shared/lib/types';

export interface IKontaktpersonProps {
    kontaktperson: IKontaktpersonForm;
    showRolleDropdown?: boolean;
    onValueChange(kontaktperson: IKontaktpersonForm): void;
}

const Kontaktperson = (props: IKontaktpersonProps) => {
    return (
        <div>
            {props.kontaktperson && (
                <>
                    {props.showRolleDropdown && (
                        <div className="row">
                            <div className="col-md-6">
                                <SelectInput
                                    {...props.kontaktperson.rolle.type}
                                    options={KontaktpersonTyperNavn.filter((x) => x !== KontaktpersonTyperNavn[KontaktpersonTyperEnum.DagligLeder]).map((name, i) => {
                                        return {
                                            text: name,
                                            value: i,
                                        } as ISelectInputOption;
                                    })}
                                    onInputChange={(name, value) => {
                                        props.onValueChange({
                                            ...props.kontaktperson,
                                            rolle: {
                                                type: {
                                                    ...props.kontaktperson.rolle.type,
                                                    value: value,
                                                },
                                                name: KontaktpersonTyperNavn[value],
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-md-6">
                            <TextInput
                                {...props.kontaktperson.fornavn}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        fornavn: updateInput(props.kontaktperson.fornavn, value),
                                    });
                                }}
                                onInputBlur={(name) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        fornavn: validateInput(props.kontaktperson.fornavn),
                                    });
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <TextInput
                                {...props.kontaktperson.etternavn}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        etternavn: updateInput(props.kontaktperson.etternavn, value),
                                    });
                                }}
                                onInputBlur={(name) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        etternavn: validateInput(props.kontaktperson.etternavn),
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <TextInput
                                {...props.kontaktperson.epost}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        epost: updateInput(props.kontaktperson.epost, value),
                                    });
                                }}
                                onInputBlur={(name) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        epost: validateInput(props.kontaktperson.epost),
                                    });
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <TextInput
                                {...props.kontaktperson.mobilnummer}
                                onInputChange={(name, value) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        mobilnummer: updateInput(props.kontaktperson.mobilnummer, value),
                                    });
                                }}
                                onInputBlur={(name) => {
                                    props.onValueChange({
                                        ...props.kontaktperson,
                                        mobilnummer: validateInput(props.kontaktperson.mobilnummer),
                                    });
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Kontaktperson;
