import React, { useState } from 'react';

export interface IFormGroupLabelText {
    label: string;
    helpText?: string;
}

const FormGroupLabelText = (props: IFormGroupLabelText) => {
    const [show, setShow] = useState(false);

    return (
        <>
            {!!props.label.length && props.label.length > 1 && (
                <>
                    <span className="form-group__label">
                        <span className="form-group__label-text" dangerouslySetInnerHTML={{ __html: props.label }}></span>
                        {props.helpText && props.helpText.length && (
                            <span className="icon icon-hjelp_stor icon--help" onClick={() => setShow(!show)} />
                        )}
                    </span>
                    {show && (
                        <div className="alert alert-success">
                            <span className="icon icon-hjelp_stor" />
                            <div dangerouslySetInnerHTML={{ __html: props.helpText }} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default FormGroupLabelText;
