export enum DokumentType {
    pdf = 'pdf',
    word = 'docx',
}

export enum DokumentStatusEnum {
    ingen = 'Ingen',
    tidligere = 'Tidligere / Signert',
    kladd = 'Kladd',
    gjeldende = 'Gjeldende / Signert',
    ingensignering = 'Ingen signeringsstatus',
    venterpaasignering = 'Venter på signering',
    signert = 'Signert',
}

export interface IEpost {
    tittel: string;
    emne: string;
    melding: string;
    tillatAnalogSignatur: boolean;
    kontaktpersoner: Array<IKontaktperson>;
}

export interface IKunde {
    id: string;
    navn: string;
    organisasjonsnummer: string;
    kundeansvarlig: IRadgiver;
    egetForetak?: boolean;
    harBegrensetKundesynlighet: boolean;
}

export interface IKundeLinkItem {
    text?: string;
    url?: string;
    target?: string;
}

export interface DokumentOversikt {
    navn: string;
    dokumenter: Array<Dokument>;
    id: string;
    opprettetUtc: string;
    veilederKanSigneres: boolean;
}

export interface Dokument {
    tittel: string;
    opprettetUtc: string;
    id: string;
    dokumentMappeId: string;
    gjeldendeTil: string;
    mimetype: string;
    dokumenttype: string;
    filstorrelseInKB: number;
    status: DokumentStatus;
    gjeldendeFra: string;
    sistOppdatertUtc: string;
    parentPdfId: string;
    signeringsstatus: DokumentStatus;
}

export interface DokumentStatus {
    type: number;
    text: string;
}

export interface IKundeFull extends IKunde {
    epost: string;
    mobilnummer: string;
    telefonnummer: string;
    dagligLeder: IKontaktperson;
    gateadresse: IKundeAdresse;
    postadresse: IKundeAdresse;
    organisasjonsform: OrganisasjonsformEnum;
    kontaktpersoner: Array<IKontaktperson>;
    rettigheter: Array<IRadgiver>;
}

export interface IRadgiver {
    aktorId: string;
    fornavn: string;
    etternavn: string;
    brukerId: string;
    innloggetRadgiver?: boolean;
    epost?: string;
}

export interface IDokumentStatistikk {
    antallDokumenter: number;
}

export interface IBruker {
    aktorId: string;
    fornavn: string;
    etternavn: string;
    brukerId: string;
    regnskapsforetakId?: string;
    regnskapsforetakOrganisasjonsnummer?: string;
    regnskapforetakNavn?: string;
    ksDebugger?: boolean;
}

export interface IKontaktperson {
    id: string;
    fornavn: string;
    etternavn: string;
    epost: string;
    mobilnummer: string;
    rolle: IKontaktpersonRolle;
}

export interface IKontaktpersonRolle {
    type: KontaktpersonTyperEnum;
    text: string;
}

export enum KontaktpersonTyperEnum {
    NotSet = 0,
    Klient = 1,
    Revisor = 2,
    Bankforbindelse = 3,
    Advokat = 4,
    DagligLeder = 5,
    OkonomiskLeder = 6,
    Regnskapssjef = 7,
    Ansatt = 8,
    Andre = 9,
}

export const KontaktpersonTyperNavn = [
    'Ikke valgt',
    'Klient',
    'Revisor',
    'Bankforbindelse',
    'Advokat',
    'Daglig leder',
    'Økonomisk leder',
    'Regnskapssjef',
    'Ansatt',
    'Andre',
];

export interface IKundeAdresse {
    adresse: string;
    postnummer: string;
    poststed: string;
    landkode: string;
}

export enum OrganisasjonsformEnum {
    undefined = 0,
    administrativ_enhet_offentlig_sektor = 1,
    annen_juridisk_person = 2,
    ansvarlig_selskap = 3,
    aksjeselskap = 4,
    allment_aksjeselskap = 5,
    selskap_med_begrenset_ansvar = 6,
    boligbyggelag = 7,
    bedrift = 8,
    andre_bo = 9,
    borettslag = 10,
    ansvarlig_selskap_med_delt_deltakeransvar = 11,
    enkeltpersonforetak = 12,
    europeisk_oekonomisk_foretaksgruppe = 13,
    eierseksjonssameie = 14,
    fylkeskommunalt_foretak = 15,
    forening_lag_innretning = 16,
    fylkeskommune = 17,
    gjensidig_forsikringsselskap = 18,
    andre_ikke_juridiske_personer = 19,
    interkommunalt_selskap = 20,
    konkursbo = 21,
    kommunalt_foretak = 22,
    den_norske_kirke = 23,
    kommune = 24,
    kommandittselskap = 25,
    kontorfellesskap = 26,
    norskregistrert_utenlandsk_foretak = 27,
    saerskilt_oppdelt_enhet = 28,
    organisasjonsledd = 29,
    andre_enkeltpersoner_registrert_i_tilknyttet_register = 30,
    pensjonskasse = 31,
    partrederi = 32,
    enkeltpersoner_som_registreres_i_Revisorregisteret = 33,
    samvirkeforetak = 34,
    tingsrettslig_sameie = 35,
    europeisk_selskap = 36,
    statsforetak = 37,
    sparebank = 38,
    staten = 39,
    stiftelse = 40,
    annet_foretak_iflg_saerskilt_lov = 41,
    tvangsregistrert_for_MVA = 42,
    utenlandsk_enhet = 43,
    verdipapirfond = 44,
    ikke_naeringsdrivende_virksomhet = 45,
}

// Veileder
export enum VeilederAnswerEnum {
    undefined,
    alternatives,
    comment,
    date,
    double,
    decimal,
    dropdown,
    multiselect,
    number,
    radiobutton,
    tabular,
    string,
    shorttext,
    mediumtext,
    longtext,
    text,
    minitext,
    microtext,
    smallnumber,
    smalldecimal,
    integer,
    smallinteger,
}

export enum VeilederAnswerNames {
    'Undefined',
    'Alternatives',
    'Comment',
    'Date',
    'Double',
    'Decimal',
    'Dropdown',
    'Multiselect',
    'Number',
    'Radiobutton',
    'Tabular',
    'String',
    'Shorttext',
    'Mediumtext',
    'Longtext',
    'Text',
    'Minitext',
    'Microtext',
    'Smallnumber',
    'Smalldecimal',
    'Integer',
    'Smallinteger',
}
// Merk: Må speile VeilederType i VeilederType.cs
export enum VeilederType {
    mal,
    veileder,
}
export const VeilederTypeNames = ['Maler', 'Veiledere'];

export interface IVeilederliste {
    hovedKategorier: Array<IVeilederHovedKategorier>;
}
export interface IVeilederHovedKategorier {
    navn: string;
    underKategorier: Array<IVeilederUnderKategorier>;
}
export interface IVeilederUnderKategorier {
    navn: string;
    veiledere: Array<IVeilederListeItem>;
}
export interface IVeilederListeItem {
    name: string;
    id: string;
    type: VeilederType;
    relevantForEgetForetak: boolean;
    relevantForKunder: boolean;
    downloadLink: string;
    summaryText: string;
}

export interface IVeilederIArbeid {
    tittel: string;
    sistOppdatertUtc: string;
    id: string;
    veilederId: string;
}

export interface IVeileder {
    name: string;
    id: string;
    type: VeilederType;
    intro: string;
    mainbody: string;
    downloadLink: string;
    activeVeiledning?: boolean;
    summaryText: string;
    veilederKanSigneres?: boolean;
    bestillDigitalSigneringBeskrivelse?: string;
    bestillDigitalSigneringLenke?: string;
    bestillDigitalSigneringLenkeTekst?: string;
    bestillDigitalSigneringTittel?: string;
    digitalSigneringBeskrivelse?: string;
    digitalSigneringTittel?: string;
}

export interface IActiveVeilederApiResponse {
    veileder: IActiveVeileder;
}

export interface IActiveVeileder {
    activeStep: number;
    title: string;
    version: number;
    schemaVersion: number;
    completed: boolean;
    pathSteps: Array<IPathSteps>;
    steps: Array<IVeilederStep>;
    includeFlags: Array<IFlags>;
    path: Array<number>;
    showSummary: boolean;
}

export interface IFlags {
    key?: string;
    include?: boolean;
}

export interface IPathSteps {
    stepNumber: number;
    stepHeader: string;
}

export interface IVeilederStep {
    stepProcessed: boolean;
    visible: boolean;
    isActive: boolean;
    hiddenStep: boolean;
    stepNumber: number;
    stepMessage?: string;
    stepHeader: string;
    description: string;
    extraDescription?: string;
    legalReferences: Array<string>;
    questions: Array<IVeilederQuestion>;
}

export interface IVeilederQuestion {
    questionNumber: number;
    questionTag?: string;
    questionText: string;
    extraQuestionDescription?: string | null;
    questionOptional?: boolean;
    questionHidden?: boolean;
    answerHidden?: boolean;
    answerType: VeilederAnswerEnum;
    alternativeValues?: Array<string> | null;
    selectedValues?: Array<string> | null;
    stringValue?: string;
    numberValue?: number;
    doubleValue?: number;
    dateValue?: string;
    tabularValue?: IVeilederTabularQuestion;
    validationErrorMessage?: string;
}

export interface IVeilederTabularQuestion {
    canAddRows: boolean;
    containsCalculations: boolean;
    columns: Array<IVeilederTabularHeaderCell>;
    rows: Array<IVeilederTabularRow>;
}

export interface IVeilederTabularRow {
    cells: Array<IVeilederTabularRowCell>;
}

export interface IVeilederTabularHeaderCell {
    type: VeilederAnswerEnum;
    number: number;
    columnName?: string;
    header?: string;
    columnIsOptional?: boolean;
    alternativesValue?: Array<string>;
    readOnly?: boolean;
    isOptional?: boolean;
}

export interface IVeilederTabularRowCell {
    numberValue?: number;
    stringValue?: string;
    doubleValue?: number;
    dateValue?: Date;
    selectedValue?: Array<string> | null;
    readOnly?: boolean;
    validationErrorMessage?: string;
}
